import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';
import AuthService from './AuthService';
import { v4 as uuidv4 } from 'uuid';
import { LP_TRACE_ID } from '@/constants/constants';

axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

class ApiService {
  inFlightRequests: Map<string, AbortController> = new Map();

  constructor(private authService: typeof AuthService) {}

  private get base() {
    return (
      import.meta.env.VITE_PROXY_URL || import.meta.env.VITE_BASE_API_URL || ''
    );
  }

  private async getHeaders() {
    const token = await this.authService.getToken();
    const headers = { [LP_TRACE_ID]: uuidv4() };
    if (token) {
      return { ...headers, Authorization: `Bearer ${token}` };
    }
    return headers;
  }

  private cancelAndCache(key: string) {
    if (this.inFlightRequests.has(key)) {
      this.inFlightRequests.get(key)?.abort();
    }

    const controller = new AbortController();
    this.inFlightRequests.set(key, controller);
    return controller;
  }

  cancelByKey(key: string) {
    this.inFlightRequests.get(key)?.abort();
    this.inFlightRequests.delete(key);
  }

  cancelAll() {
    this.inFlightRequests.forEach((controller) => controller.abort());
    this.inFlightRequests.clear();
  }

  hasInflightRequest(key: string) {
    return this.inFlightRequests.has(key);
  }

  private async handleInflight<T>(
    response: Promise<AxiosResponse<T>>,
    key: string,
  ) {
    return response
      .then((res) => {
        this.inFlightRequests.delete(key);
        return res;
      })
      .catch((err: AxiosError) => {
        if (err.code === AxiosError.ERR_CANCELED) {
          throw err;
        }
        this.inFlightRequests.delete(key);
        throw err;
      });
  }

  async get<T>(
    path: string,
    key: string,
    params?: any,
    config?: AxiosRequestConfig,
    ignoreBase: boolean = false,
  ) {
    const controller = this.cancelAndCache(key);
    const authHeader = await this.getHeaders();
    const baseURL = ignoreBase ? '' : this.base;
    return this.handleInflight<T>(
      axios.get<T>(`${baseURL}/${path}`, {
        ...config,
        headers: authHeader,
        signal: controller.signal,
        params,
      }),
      key,
    );
  }

  async post<T>(path: string, body: any, key: string, headers?: any) {
    const controller = this.cancelAndCache(key);
    const authHeader = await this.getHeaders();
    return this.handleInflight<T>(
      axios.post<T>(`${this.base}/${path}`, body, {
        headers: {
          ...headers,
          ...authHeader,
        },
        signal: controller.signal,
      }),
      key,
    );
  }

  async put<T>(path: string, body: any, key: string, headers?: any) {
    const controller = this.cancelAndCache(key);
    const authHeader = await this.getHeaders();
    return this.handleInflight<T>(
      axios.put<T>(`${this.base}/${path}`, body, {
        headers: {
          ...headers,
          ...authHeader,
        },
        signal: controller.signal,
      }),
      key,
    );
  }

  async patch<T>(path: string, body: any, key: string) {
    const controller = this.cancelAndCache(key);
    const authHeader = await this.getHeaders();
    return this.handleInflight<T>(
      axios.patch<T>(`${this.base}/${path}`, body, {
        headers: authHeader,
        signal: controller.signal,
      }),
      key,
    );
  }

  async delete<T>(
    path: string,
    key: string,
    params?: any,
    body?: any,
    headers?: any,
  ) {
    const controller = this.cancelAndCache(key);
    const authHeader = await this.getHeaders();
    return this.handleInflight(
      axios.delete<T>(`${this.base}/${path}`, {
        headers: {
          ...headers,
          ...authHeader,
        },
        signal: controller.signal,
        params,
        data: body,
      }),
      key,
    );
  }
}

export default new ApiService(AuthService);
