<template>
  <v-app
    :class="{
      'bg-primary-lighten-6': !theme.current.value.dark,
      'bg-base-ascent-navy': theme.current.value.dark,
    }"
  >
    <v-fade-transition hide-on-leave>
      <VAppLoader v-if="loading" />
      <router-view v-else-if="isAppEnabled" />
      <VUnauthorizedUser v-else />
    </v-fade-transition>
  </v-app>

  <VDialogConfirm />
  <BaseSnackbar />
</template>

<script lang="ts" setup>
import { watch, onMounted, computed } from 'vue';
import { useCurrentUser } from 'vuefire';
import { useModelsStore } from '@/store/models';
import { useTheme } from 'vuetify';
import { useLDClient, useLDFlag } from 'launchdarkly-vue-client-sdk';
import { useRouter } from 'vue-router';
import BaseSnackbar from './components/BaseSnackbar.vue';
import { useAppStore } from './store/app';
import {
  DEFAULT_ACCOUNT_ID,
  LD_FLAGS,
  MAX_WORD_LIMIT,
  STORAGE_KEYS,
} from './constants/constants';
import { IUserData, useUserStore } from './store/user';
import { useCategoriesStore } from './store/categories';
import VDialogConfirm from './components/VDialogConfirm.vue';
import { useUsersStore } from './store/users';
import { useConversationStore } from './store/conversation';
import { useNotifyStore } from './store/notify';
import { useQueryStore } from './store/query';
import { useSummaryStore } from './store/summary';
import { storeToRefs } from 'pinia';
import VAppLoader from '@/components/VAppLoader.vue';
import { useAgentWidgetStore } from './store/agentWidget';
import VUnauthorizedUser from '@/components/VUnauthorizedUser.vue';
import { LDContext } from 'launchdarkly-js-client-sdk';
import { useFlowsStore } from './store/flows';
import { useKnowledgebasesStore } from './store/knowledgebases';

const { showSnackbar } = useNotifyStore();
const user = useCurrentUser();
const appStore = useAppStore();
const userStore = useUserStore();
const { userData } = storeToRefs(userStore);
const { resetConversations } = useConversationStore();
const { resetModels } = useModelsStore();
const { resetCategories } = useCategoriesStore();
const { resetUsers } = useUsersStore();
const { resetAgentWidget } = useAgentWidgetStore();
const { resetQuery } = useQueryStore();
const { resetSummary } = useSummaryStore();
const { resetApp } = useAppStore();
const { resetFlows } = useFlowsStore();
const { resetKnowledgebases } = useKnowledgebasesStore();
const theme = useTheme();
const ldClient = useLDClient();
const router = useRouter();

const { loading: appLoading, appEnv } = storeToRefs(appStore);
const {
  loading: userLoading,
  loaded: userLoaded,
  onLoginPage,
} = storeToRefs(userStore);

const loading = computed(
  () =>
    (appLoading.value || userLoading.value) &&
    !userLoaded.value &&
    !onLoginPage.value,
);

const isAppEnabled = useLDFlag<boolean>(LD_FLAGS.APP_ENABLED, true);

const initPendo = (uid: string, email: string, accountId?: string) => {
  const { zone, phase, grid, datacenter } = appEnv.value;
  (window as any).pendo?.initialize({
    visitor: {
      id: uid,
    },
    account: {
      id: accountId ?? DEFAULT_ACCOUNT_ID,
      zone,
      phase,
      datacenter,
      grid,
      is_lpa: email.endsWith('@liveperson.com'),
    },
  });
};

const initApp = async (user: IUserData) => {
  appStore.loading = true;

  const context: LDContext = {
    kind: 'multi',
    user: {
      key: user.uid,
      name: user.display_name ?? undefined,
      email: user.email ?? undefined,
    },
  };

  if (user.account_id) {
    context.account = {
      key: user.account_id,
    };
  }

  initPendo(user.uid, user.email ?? '', user.account_id);
  const flags = await ldClient.identify(context);

  appStore.$patch({
    loading: false,
    maxWordLimit: flags[LD_FLAGS.MAX_WORD_LIMIT] ?? MAX_WORD_LIMIT,
  });
};

watch(userData, async (userData) => {
  if (!userData) {
    await router.replace('/login');
    return Promise.all([
      resetCategories(),
      resetModels(),
      resetUsers(),
      resetConversations(),
      resetSummary(),
      resetQuery(),
      resetAgentWidget(),
      resetFlows(),
      resetKnowledgebases(),
      resetApp(),
    ]);
  }
  if (user.value && !user.value.emailVerified) {
    return showSnackbar(
      'Your email requires verification',
      'Please check your inbox or junk items and follow the verification link',
      -1,
    );
  }
  await initApp(userData);
});

onMounted(() => {
  const savedTheme = localStorage.getItem(STORAGE_KEYS.THEME);
  if (savedTheme) {
    theme.global.name.value = savedTheme;
  } else {
    const prefersDark = window.matchMedia(
      '(prefers-color-scheme: dark)',
    )?.matches;
    const themeValue = prefersDark ? 'dark' : 'light';
    theme.global.name.value = themeValue;
    localStorage.setItem('__theme__', themeValue);
  }
});
</script>
