import { loadFonts } from './webfontloader';
import vuetify from './vuetify';
import router from '../router';

import { registerSentry } from './sentry';
import { firebaseApp } from './firebase';
import { VueFire, VueFireAuth } from 'vuefire';
import { registerPendo } from './pendo';
import { LDPlugin } from 'launchdarkly-vue-client-sdk';
import type { App } from 'vue';
import { HOST_MAP, DEFAULT_APP_ENV } from '@/constants/constants';
import { registerPinia } from './pinia';

export function registerPlugins(app: App) {
  loadFonts();
  registerSentry(app, router);
  registerPendo(app);

  const appEnv = HOST_MAP[location.hostname] ?? DEFAULT_APP_ENV;

  app
    .use(LDPlugin, { clientSideID: appEnv.ldClient })
    .use(vuetify)
    .use(router)
    .use(registerPinia(router))
    .use(VueFire, {
      firebaseApp,
      modules: [VueFireAuth()],
    });
}
