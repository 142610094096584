import type { App } from 'vue';
import type { Router } from 'vue-router';
import * as Sentry from '@sentry/vue';
// import { BrowserTracing } from '@sentry/tracing';
import packageJson from '../../package.json';
import { HOST_MAP, DEFAULT_APP_ENV } from '@/constants/constants';

export function registerSentry(app: App, router: Router) {
  const appEnv = HOST_MAP[location.hostname] ?? DEFAULT_APP_ENV;
  Sentry.init({
    app,
    dsn: 'https://bda7c2e50c0e4803a806754e11c8ae6e@o1431718.ingest.sentry.io/4504468632043520',
    integrations: [
      // new BrowserTracing({
      //   routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      //   tracePropagationTargets: Object.keys(HOST_MAP),
      // }),
    ],
    environment: appEnv.env,
    release: packageJson.version.slice(0, 3),
    tracesSampleRate: Number(import.meta.env.VITE_SENTRY_SAMPLE_RATE) ?? 0.5,
  });
}
