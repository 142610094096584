import { defineStore } from 'pinia';
import { API_ROUTES, ACTION_KEYS } from '@/constants/constants';
import ApiService from '@/services/ApiService';
import ErrorService from '@/services/ErrorService';
import { IUser } from '@/interfaces/interfaces';
import { useNotifyStore } from './notify';
import { CreateUserDTO, UpdateUserDTO } from '@/interfaces/dtos';

export interface IUsersState {
  loaded: boolean;
  loadedDetails: boolean;
  loading: boolean;
  userEntities: Record<string, IUser>;
  userIds: string[];
  filter?: string | null;
}

export const useUsersStore = defineStore('users', {
  state: (): IUsersState => ({
    loaded: false,
    loadedDetails: false,
    loading: false,
    userEntities: {},
    userIds: [],
    filter: null,
  }),
  getters: {
    users: (state) =>
      state.userIds
        .map((id) => state.userEntities[id])
        .sort((a, b) => (b.updated_at ?? 0) - (a.updated_at ?? 0)),
    usersFiltered(): IUser[] {
      const filter = this.filter;

      if (!filter) {
        return this.users;
      }

      return this.users.filter((user) => {
        const fields = [user.display_name, user.email, ...(user.roles ?? [])];
        if (user.account_id) fields.push(user.account_id);
        const textToSearch = fields.join('').toLowerCase();
        return textToSearch.toLowerCase().includes(filter.toLowerCase());
      });
    },
  },
  actions: {
    async getUsers(includeDetails: boolean = false) {
      this.loading = true;
      const actionKey = ACTION_KEYS.GET_USERS;
      try {
        const url = includeDetails
          ? API_ROUTES.USERS_DETAILS()
          : API_ROUTES.USERS();
        const { data } = await ApiService.get<IUser[]>(url, actionKey);
        const ids: string[] = [];
        const entities: Record<string, IUser> = data.reduce((prev, user) => {
          ids.push(user.uid);
          prev[user.uid] = user;
          return prev;
        }, {} as Record<string, IUser>);
        this.$patch({
          userEntities: entities,
          userIds: ids,
          loaded: true,
          loadedDetails: includeDetails,
        });
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = ApiService.hasInflightRequest(actionKey);
      }
    },
    async createUser(body: CreateUserDTO) {
      const { showSnackbar } = useNotifyStore();
      const actionKey = ACTION_KEYS.CREATE_USER;
      this.loading = true;

      try {
        const url = API_ROUTES.USERS();
        const { data } = await ApiService.post<IUser>(url, body, actionKey);
        this.$patch({
          userIds: [data.uid, ...this.userIds],
          userEntities: {
            ...this.userEntities,
            [data.uid]: data,
          },
        });
        showSnackbar(`User created: ${data.uid}`);
        return data;
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = ApiService.hasInflightRequest(actionKey);
      }
    },
    async updateUser(body: UpdateUserDTO, uid: string) {
      const { showSnackbar } = useNotifyStore();
      const actionKey = `${ACTION_KEYS.UPDATE_USER}${uid}`;
      this.loading = true;

      try {
        const url = API_ROUTES.USERS_BY_ID(uid);
        const { data } = await ApiService.put<IUser>(url, body, actionKey);
        this.$patch({
          userEntities: {
            ...this.userEntities,
            [data.uid]: data,
          },
        });
        showSnackbar(`User updated: ${uid}`);
        return this.userEntities[uid];
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = ApiService.hasInflightRequest(actionKey);
      }
    },
    async deleteUser(uid: string) {
      const { showSnackbar } = useNotifyStore();
      const actionKey = ACTION_KEYS.DELETE_USER;
      this.loading = true;

      try {
        const url = API_ROUTES.USERS_BY_ID(uid);
        const { data } = await ApiService.delete<string>(url, actionKey);
        const { [data]: toRemove, ...userEntities } = this.userEntities;
        const index = this.userIds.findIndex((uid) => uid === toRemove.uid);
        this.$patch({
          userIds: [
            ...this.userIds.slice(0, index),
            ...this.userIds.slice(index + 1),
          ],
          userEntities: {
            ...userEntities,
          },
        });
        showSnackbar(`User deleted: ${uid}`);
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = ApiService.hasInflightRequest(actionKey);
      }
    },
    resetUsers() {
      this.$reset();
    },
  },
});
