import { defineStore } from 'pinia';
import AuthService from '@/services/AuthService';
import {
  API_ROUTES,
  ACTION_KEYS,
  PERMISSIONS,
  LOGIN_METHOD,
  STORAGE_KEYS,
} from '@/constants/constants';
import ApiService from '@/services/ApiService';
import ErrorService from '@/services/ErrorService';
import { UpdateUserModelsDTO } from '@/interfaces/dtos';
import { nextTick } from 'vue';
import {
  ROLES,
  ROLES_EXTERNAL,
  ROLES_INTERNAL,
  ROLES_PRIVILEGED,
} from '@/constants/roles';

export interface IUserData {
  uid: string;
  email: string;
  roles: ROLES[];
  display_name?: string;
  permissions: PERMISSIONS[];
  favorite_models: string[];
  account_id?: string;
  terms_agreed: boolean;
  is_lpa: boolean;
  is_cc_user: string;
}

export interface IUserState {
  loading: boolean;
  loaded: boolean;
  userData: IUserData | null;
  loginMethod: LOGIN_METHOD;
  onLoginPage: boolean;
}

export const useUserStore = defineStore('user', {
  state: (): IUserState => ({
    loading: false,
    loaded: false,
    userData: null,
    loginMethod:
      (localStorage.getItem(STORAGE_KEYS.LOGIN_METHOD) as LOGIN_METHOD) ??
      LOGIN_METHOD.FIREBASE_AUTH,
    onLoginPage: false,
  }),
  getters: {
    isPrivileged(): boolean {
      return !!this.hasRole(ROLES_PRIVILEGED);
    },
    isFirebaseLoginMethod(): boolean {
      return this.loginMethod === LOGIN_METHOD.FIREBASE_AUTH;
    },
    favoriteModels: (state) =>
      (state.userData?.favorite_models ?? []).reduce((prev, id) => {
        prev.set(id, true);
        return prev;
      }, new Map<string, boolean>()),
    hasRole: (state) => (allowedRoles: ROLES[]) =>
      !!state.userData?.roles.some((role) =>
        allowedRoles.some((allowedRole) => allowedRole === role),
      ),
    hasAccountId: (state) => (accountId: string) =>
      state.userData?.account_id === accountId,
    hasPermission: (state) => (allowedPermission: PERMISSIONS) =>
      !!state.userData?.permissions.some(
        (permission) => allowedPermission === permission,
      ),
    isInternal(): boolean {
      return !!this.hasRole(ROLES_INTERNAL);
    },
    isExternal(): boolean {
      return !!this.hasRole(ROLES_EXTERNAL);
    },
    isExternalConsumer: (state) =>
      state.userData?.roles.includes(ROLES.EXTERNAL_CONSUMER) ?? false,
    accountId(state) {
      return state.userData?.account_id;
    },
    defaultAccountId(): string | undefined {
      return this.accountId;
    },
    hasConvCloudSummaryPermission: (state) =>
      state.userData?.permissions.includes(PERMISSIONS.CONV_CLOUD_SUMMARY) ??
      false,
    hasTranscriptAnalyzerPermission: (state) =>
      state.userData?.permissions.includes(PERMISSIONS.TRANSCRIPT_ANALYZER) ??
      false,
  },
  actions: {
    setLoginMethod(method: LOGIN_METHOD) {
      this.loginMethod = method;
      localStorage.setItem(STORAGE_KEYS.LOGIN_METHOD, method);
    },
    async getUserData() {
      this.loading = true;
      try {
        const { data } = await ApiService.get<IUserData>(
          API_ROUTES.USERS_SELF(),
          ACTION_KEYS.GET_USER_DATA,
        );
        this.$patch({ userData: data, loaded: true, onLoginPage: false });
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = false;
      }
      return this.userData;
    },
    async agreeTerms() {
      this.loading = true;
      try {
        if (!this.userData) {
          throw Error('User not authenticated');
        }
        await ApiService.get<IUserData>(
          API_ROUTES.USERS_TERMS_BY_ID(this.userData.uid),
          ACTION_KEYS.GET_USER_DATA,
        );
        this.$patch({ userData: { terms_agreed: true }, loaded: true });
        this.userData;
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = false;
      }
      return this.userData;
    },
    async updateUserModels(modelIds: string[], isFavorite: boolean) {
      if (!this.userData) {
        console.error('Missing user data');
        return;
      }

      const actionKey = `${ACTION_KEYS.UPDATE_USER_MODELS}`;
      this.loading = true;
      try {
        const updatedBots = new Map(this.favoriteModels);
        modelIds.forEach((id) => updatedBots.set(id, isFavorite));
        const favorite_models = Array.from(updatedBots.entries())
          .filter(([, state]) => state)
          .map(([id]) => id);
        const body: UpdateUserModelsDTO = {
          favorite_models,
        };
        this.$patch({ userData: { favorite_models } });
        const url = API_ROUTES.USERS_MODELS_BY_ID(this.userData.uid);
        await ApiService.put<IUserData>(url, body, actionKey);
        return this.userData;
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = ApiService.hasInflightRequest(actionKey);
      }
    },
    async signInWithGoogle() {
      this.loading = true;
      try {
        return await AuthService.signInWithGoogle();
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = false;
      }
      return null;
    },
    async signInWithEmailPassword(email: string, password: string) {
      this.loading = true;
      try {
        return await AuthService.signInWithEmail(email, password);
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = false;
      }
      return null;
    },
    async signInWithCC(accountId: string) {
      this.loading = true;
      const URL = API_ROUTES.SSO_LOGIN(accountId);
      nextTick(() => (window.location.href = `/${URL}`));
    },
    async signOut(reset: boolean = true) {
      if (this.isFirebaseLoginMethod) {
        return this.signOutFirebase(reset);
      }
      return this.signOutCC(reset);
    },
    async signOutFirebase(reset: boolean = true) {
      try {
        return await AuthService.signOutFirebase();
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        if (reset) this.$reset();
      }
    },
    async signOutCC(reset: boolean = true) {
      try {
        return await AuthService.signOutCC();
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        if (reset) this.$reset();
      }
    },
  },
});
