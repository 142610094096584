import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from 'vue-router';
import { useUserStore } from '@/store/user';
import HelperService from '@/services/HelperService';
import { getCurrentUser } from 'vuefire';
import {
  CAN_ACCESS_TAB,
  NAV_TABS,
  ROUTE_NAMES,
  ROUTE_TITLES,
  TABS,
  TAB_IDS,
  TITLE_SUFFIX,
  findFirstAvailableTabId,
} from '@/constants/routes';

declare module 'vue-router' {
  interface RouteMeta {
    tabId?: NAV_TABS;
  }
}

const isAuthenticated = async (to: RouteLocationNormalized) => {
  const { userData, getUserData, isFirebaseLoginMethod } = useUserStore();
  let user = userData;
  if (!userData) {
    // if firebase method, wait for user to be loaded to get token
    if (isFirebaseLoginMethod) await getCurrentUser();
    user = await getUserData();
  }

  if (!user) {
    document.title = ROUTE_TITLES[ROUTE_NAMES.LOGIN];
    return {
      name: ROUTE_NAMES.LOGIN,
      query: { redirect: to.fullPath },
    };
  }

  if (!user.terms_agreed) {
    HelperService.showTermsDialog();
  }
};

const canAccessTab = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  if (!to.meta.tabId) {
    return next(from);
  }
  if (CAN_ACCESS_TAB[to.meta.tabId]()) {
    return next();
  }
  const targetTab = TABS[to.meta.tabId];
  const childIds = targetTab.parentId
    ? TAB_IDS.filter((id) => TABS[id].parentId === targetTab.parentId)
    : [];
  const remainingIds = TAB_IDS.filter((id) => !childIds.includes(id));
  const id = findFirstAvailableTabId(childIds.concat(remainingIds));
  if (id) {
    return next({ name: TABS[id].routeName });
  }
  console.error('No available route to find, signing out');
  const { signOut } = useUserStore();
  signOut();
  return;
};

const routes: RouteRecordRaw[] = [
  {
    path: '/login',
    name: ROUTE_NAMES.LOGIN,
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/agent-widget',
    beforeEnter: isAuthenticated,
    component: () => import('@/layouts/AgentWidget.vue'),
    children: [
      {
        path: '',
        meta: {
          tabId: NAV_TABS.AGENT_WIDGET,
        },
        beforeEnter: canAccessTab,
        name: ROUTE_NAMES.AGENT_WIDGET,
        component: () => import('@/views/AgentWidget.vue'),
      },
    ],
  },
  {
    path: '/',
    beforeEnter: isAuthenticated,
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: 'flows',
        meta: {
          tabId: NAV_TABS.FLOWS,
        },
        beforeEnter: canAccessTab,
        children: [
          {
            path: '',
            component: () => import('@/views/HeaderContentSheet.vue'),
            children: [
              {
                path: '',
                name: ROUTE_NAMES.FLOWS,
                components: {
                  Header: () => import('@/components/Flows/FlowsActions.vue'),
                  Content: () => import('@/components/Flows/FlowsTable.vue'),
                },
              },
            ],
          },
          {
            path: ':id',
            name: ROUTE_NAMES.FLOWS_SELECT,
            props: true,
            component: () => import('@/components/Flows/FlowsSelect.vue'),
          },
        ],
      },
      {
        path: 'conversation-history',
        meta: {
          tabId: NAV_TABS.CONVERSATION_HISTORY,
        },
        beforeEnter: canAccessTab,
        component: () => import('@/views/HeaderContentSheet.vue'),
        children: [
          {
            path: '',
            name: ROUTE_NAMES.CONVERSATION_HISTORY,
            components: {
              Header: () =>
                import(
                  '@/components/ConversationHistory/ConversationHistoryActions.vue'
                ),
              Content: () =>
                import(
                  '@/components/ConversationHistory/ConversationHistoryTable.vue'
                ),
            },
          },
        ],
      },
      {
        path: 'conversation-playground',
        meta: {
          tabId: NAV_TABS.CONVERSATION_PLAYGROUND,
        },
        beforeEnter: canAccessTab,
        children: [
          {
            path: '',
            name: ROUTE_NAMES.CONVERSATION_PLAYGROUND,
            component: () =>
              import(
                '@/components/ConversationPlayground/ConversationPlayground.vue'
              ),
          },
          {
            path: ':id',
            name: ROUTE_NAMES.CONVERSATION_PLAYGROUND_SELECT,
            props: true,
            component: () =>
              import(
                '@/components/ConversationPlayground/ConversationPlayground.vue'
              ),
          },
        ],
      },
      {
        path: 'simulations',
        meta: {
          tabId: NAV_TABS.SIMULATIONS,
        },
        beforeEnter: canAccessTab,
        children: [
          {
            path: '',
            component: () => import('@/views/HeaderContentSheet.vue'),
            children: [
              {
                path: '',
                name: ROUTE_NAMES.SIMULATIONS,
                components: {
                  Header: () =>
                    import('@/components/Simulator/SimulatorActions.vue'),
                  Content: () =>
                    import('@/components/Simulator/SimulatorTable.vue'),
                },
              },
            ],
          },
          {
            path: ':id',
            name: ROUTE_NAMES.SIMULATIONS_SELECT,
            props: true,
            component: () =>
              import('@/components/Simulator/SimulatorSelect.vue'),
          },
        ],
      },
      {
        path: 'transcript-analyzer',
        meta: {
          tabId: NAV_TABS.TRANSCRIPT_ANALYSIS,
        },
        beforeEnter: canAccessTab,
        children: [
          {
            path: '',
            component: () => import('@/views/HeaderContentSheet.vue'),
            children: [
              {
                path: '',
                name: ROUTE_NAMES.TRANSCRIPT_ANALYSIS,
                components: {
                  Header: () =>
                    import(
                      '@/components/TranscriptAnalysis/TranscriptAnalysisActions.vue'
                    ),
                  Content: () =>
                    import(
                      '@/components/TranscriptAnalysis/TranscriptAnalysisTable.vue'
                    ),
                },
              },
            ],
          },
          {
            path: ':id',
            name: ROUTE_NAMES.TRANSCRIPT_ANALYSIS_SELECT,
            props: true,
            component: () =>
              import(
                '@/components/TranscriptAnalysis/TranscriptAnalysisSelect.vue'
              ),
          },
        ],
      },
      {
        path: 'extras',
        children: [
          {
            path: 'connectors',
            meta: {
              tabId: NAV_TABS.CONNECTORS,
            },
            beforeEnter: canAccessTab,
            component: () => import('@/views/HeaderContentSheet.vue'),
            children: [
              {
                path: '',
                name: ROUTE_NAMES.CONNECTORS,
                components: {
                  Header: () =>
                    import('@/components/Connectors/ConnectorsActions.vue'),
                  Content: () =>
                    import('@/components/Connectors/ConnectorsTable.vue'),
                },
              },
            ],
          },
          {
            path: 'knowledgebases',
            meta: {
              tabId: NAV_TABS.KNOWLEDGEBASES,
            },
            beforeEnter: canAccessTab,
            component: () => import('@/views/HeaderContentSheet.vue'),
            children: [
              {
                path: '',
                name: ROUTE_NAMES.KNOWLEDGEBASES,
                components: {
                  Header: () =>
                    import(
                      '@/components/Knowledgebases/KnowledgebasesActions.vue'
                    ),
                  Content: () =>
                    import(
                      '@/components/Knowledgebases/KnowledgebasesTable.vue'
                    ),
                },
              },
            ],
          },
          {
            path: 'bots',
            meta: {
              tabId: NAV_TABS.BOTS,
            },
            beforeEnter: canAccessTab,
            component: () => import('@/views/HeaderContentSheet.vue'),
            children: [
              {
                path: '',
                name: ROUTE_NAMES.BOTS,
                components: {
                  Header: () => import('@/components/Bots/BotsActions.vue'),
                  Content: () => import('@/components/Bots/BotsTable.vue'),
                },
              },
            ],
          },
          {
            path: 'summaries',
            meta: {
              tabId: NAV_TABS.SUMMARIES,
            },
            beforeEnter: canAccessTab,
            component: () => import('@/views/HeaderContentSheet.vue'),
            children: [
              {
                path: '',
                name: ROUTE_NAMES.SUMMARIES,
                components: {
                  Header: () =>
                    import('@/components/Summaries/SummariesActions.vue'),
                  Content: () =>
                    import('@/components/Summaries/SummariesTable.vue'),
                },
              },
            ],
          },
        ],
      },
      {
        path: 'admin',
        children: [
          {
            path: 'users',
            meta: {
              tabId: NAV_TABS.USERS,
            },
            beforeEnter: canAccessTab,
            component: () => import('@/views/HeaderContentSheet.vue'),
            children: [
              {
                path: '',
                name: ROUTE_NAMES.USERS,
                components: {
                  Header: () => import('@/components/Users/UsersActions.vue'),
                  Content: () => import('@/components/Users/UsersTable.vue'),
                },
              },
            ],
          },
          {
            path: 'categories',
            meta: {
              tabId: NAV_TABS.CATEGORIES,
            },
            beforeEnter: canAccessTab,
            component: () => import('@/views/HeaderContentSheet.vue'),
            children: [
              {
                path: '',
                name: ROUTE_NAMES.CATEGORIES,
                components: {
                  Header: () =>
                    import('@/components/Categories/CategoriesActions.vue'),
                  Content: () =>
                    import('@/components/Categories/CategoriesTable.vue'),
                },
              },
            ],
          },
          {
            path: 'sandbox',
            meta: {
              tabId: NAV_TABS.SANDBOX,
            },
            beforeEnter: canAccessTab,
            children: [
              {
                path: '',
                name: ROUTE_NAMES.SANDBOX,
                component: () => import('@/views/Sandbox.vue'),
              },
              {
                path: 'flow',
                name: ROUTE_NAMES.SANDBOX_FLOW,
                component: () => import('@/components/Sandbox/SandboxFlow.vue'),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '',
    redirect: {
      name: ROUTE_NAMES.FLOWS,
    },
  },
  {
    path: '/:catchAll(.*)',
    redirect: {
      name: ROUTE_NAMES.FLOWS,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to) => {
  document.title = ROUTE_TITLES[to.name as ROUTE_NAMES] ?? TITLE_SUFFIX;
});

export default router;
