export enum KNOWLEDGE_FILTER_TYPE {
  MY_KNOWLEDGE = 'MY_KNOWLEDGE',
  ALL_KNOWLEDGE = 'ALL_KNOWLEDGE',
  FLOW_TEMPLATES = 'FLOW_TEMPLATES',
  FLOW_USE_CASES = 'FLOW_USE_CASES',
}

export const KNOWLEDGE_FILTER_ITEMS = [
  {
    type: 'item',
    title: 'All Knowledge',
    value: KNOWLEDGE_FILTER_TYPE.ALL_KNOWLEDGE,
  },
  {
    type: 'item',
    title: 'My Knowledge',
    value: KNOWLEDGE_FILTER_TYPE.MY_KNOWLEDGE,
  },
];
