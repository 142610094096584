export const TEST_IDS = {
  common: {
    dialog: 'tCommon__dialog',
    snackbar: 'tCommon__snackbar',
    chipCategory: 'tCommon__chipCategory',
    chipScore: 'tCommon__chipScore',
    themeToggle: 'tCommon__themeToggle',
    userAvatar: 'tCommon__userAvatar',
    userInfo: 'tCommon__userInfo',
  },
  statusIndicator: {
    completeIcon: 'tStatusIndicator__completeIcon',
    inProgressIcon: 'tStatusIndicator__inProgressIcon',
    errorIcon: 'tStatusIndicator__errorIcon',
  },
  filterDialog: {
    chips: 'tFilterDialog__chips',
    addFilterButton: 'tFilterDialog__addFilterButton',
    addFilterIcon: 'tFilterDialog__addFilterIcon',
    formCheckbox: 'tFilterDialog__formCheckbox',
    formSelect: 'tFilterDialog__formSelect',
    formTextfield: 'tFilterDialog__formTextfield',
    cancelButton: 'tFilterDialog__cancelButton',
    saveButton: 'tFilterDialog__saveButton',
  },
  login: {
    emailInput: 'tLogin__emailInput',
    passwordInput: 'tLogin__passwordInput',
    accountIdInput: 'tLogin__accountIdInput',
    signInWithEmailButton: 'tLogin__signInWithEmailButton',
    signInWithGoogleButton: 'tLogin__signInWithGoogleButton',
    signInWithCCButton: 'tLogin__signInWithCCButton',
    signInMethodLink: 'tLogin__signInMethodLink',
  },
  nav: {
    agentWidget: 'tNav__agentWidget',
    flows: 'tNav__flows',
    simulations: 'tNav__simulations',
    transcriptAnalyzer: 'tNav__transcriptAnalyzer',
    extras: 'tNav__extras',
    conversationHistory: 'tNav__conversationHistory',
    conversationPlayground: 'tNav__conversationPlayground',
    bots: 'tNav__bots',
    knowledge: 'tNav__knowledge',
    connectors: 'tNav__connectors',
    categories: 'tNav__categories',
    summaries: 'tNav__summaries',
    admin: 'tNav__admin',
    users: 'tNav__users',
    sandbox: 'tNav__sandbox',
  },
  userInfo: {
    logoutButton: 'tUserInfo__logoutButton',
  },
  modelNavigator: {
    tabBotsButton: 'tModelNavigator__tabBotsButton',
    tabFlowsButton: 'tModelNavigator__tabFlowsButton',
    modelNavigatorToggle: 'tModelNavigator__modelNavigatorToggle',
    modelCard: 'tModelNavigator__modelCard',
    modelFavoriteButton: 'tModelNavigator__modelFavoriteButton',
  },
  settingsModels: {
    createButton: 'tSettingsModels__createButton',
    formBasicButton: 'tSettingsModels__formBasicButton',
    formAdvancedButton: 'tSettingsModels__formAdvancedButton',
    formPublicSwitch: 'tSettingsModels__formPublicSwitch',
    formEnabledSwitch: 'tSettingsModels__formEnabledSwitch',
    formCategorySelect: 'tSettingsModels__formCategorySelect',
    formModelTypeSelect: 'tSettingsModels__formModelTypeSelect',
    formDisplayNameText: 'tSettingsModels__formDisplayNameText',
    formDisplayDescriptionText: 'tSettingsModels__formDisplayDescriptionText',
    formGreetingText: 'tSettingsModels__formGreetingText',
    modelRow: 'tSettingsModels__modelRow',
    modelEnabledToggle: 'tSettingsModels__modelEnabledToggle',
    modelFavoriteToggle: 'tSettingsModels__modelFavoriteToggle',
    modelTitle: 'tSettingsModels__modelTitle',
    modelType: 'tSettingsModels__modelType',
    modelCategory: 'tSettingsModels__modelCategory',
  },
  settingsFlows: {
    nodeTemplatesGroupAgents: 'tSettingsFlows__nodeTemplatesGroupAgents',
    nodeTemplatesGroupChains: 'tSettingsFlows__nodeTemplatesGroupChains',
    nodeTemplatesGroupLLMs: 'tSettingsFlows__nodeTemplatesGroupLLMs',
    nodeTemplatesGroupEmbeddings:
      'tSettingsFlows__nodeTemplatesGroupEmbeddings',
    nodeTemplatesGroupMemory: 'tSettingsFlows__nodeTemplatesGroupMemory',
    nodeTemplatesGroupPrompts: 'tSettingsFlows__nodeTemplatesGroupPrompts',
    nodeTemplatesGroupTools: 'tSettingsFlows__nodeTemplatesGroupTools',
    nodeTemplatesGroupRetrievers:
      'tSettingsFlows__nodeTemplatesGroupRetrievers',

    settingsButton: 'tSettingsFlows__settingsButton',
    exportButton: 'tSettingsFlows__exportButton',
    saveButton: 'tSettingsFlows__saveButton',

    exportFlowToCBButton: 'tSettingsFlows__exportFlowToCBButton',
    exportFlowAsJsonButton: 'tSettingsFlows__exportFlowAsJsonButton',
    copyFlowConvVarsPostProcessCodeButton:
      'tSettingsFlows__copyFlowConvVarsPostProcessCodeButton',

    validateFlowButton: 'tSettingsFlows__validateFlowButton',
    testFlowButton: 'tSettingsFlows__testFlowButton',

    testerMessageItem: 'tSettingsFlows__testerMessageItem',
    testerMessageInput: 'tSettingsFlows__testerMessageInput',
    testerMessageSendButton: 'tSettingsFlows__testerMessageSendButton',
  },
  settingsKnowledgebases: {
    createButton: 'tSettingsKnowledgebases__createButton',
    formSourceAddButton: 'tSettingsKnowledgebases__formSourceAddButton',
    formSourceList: 'tSettingsKnowledgebases__formSourceList',
    formSourceUploadInput: 'tSettingsKnowledgebases__formSourceUploadInput',
    formSourceUploadText: 'tSettingsKnowledgebases__formSourceUploadText',
    formSourceTextInput: 'tSettingsKnowledgebases__formSourceTextInput',
    formSourceDownloadButton:
      'tSettingsKnowledgebases__formSourceDownloadButton',
    formSourceConversationInput:
      'tSettingsKnowledgebases__formSourceConversationInput',
    formArticlesDownloadButton:
      'tSettingsKnowledgebases__formArticlesDownloadButton',
    formArticlesEditButton: 'tSettingsKnowledgebases__formArticlesEditButton',
    formSourceRemoveButton: 'tSettingsKnowledgebases__formSourceRemoveButton',
    formEmbeddingsProviderList:
      'tSettingsKnowledgebases__formEmbeddingsProviderList',
    articlesFilterInput: 'tSettingsKnowledgebases__articlesFilterInput',
    articlesTextInput: 'tSettingsKnowledgebases__articlesTextInput',
    articlesTextDeleteButton:
      'tSettingsKnowledgebases__articlesTextDeleteButton',
    articlesAddButton: 'tSettingsKnowledgebases__articlesAddButton',
    articlesCancelButton: 'tSettingsKnowledgebases__articlesCancelButton',
    articlesSubmitButton: 'tSettingsKnowledgebases__articlesSubmitButton',
  },
  conversation: {
    mainComponent: 'tConversation__mainComponent',
    asideComponent: 'tConversation__asideComponent',
    messageItem: 'tConversation__messageItem',
    messageInput: 'tConversation__messageInput',
    messageSendButton: 'tConversation__messageSendButton',
    messageClearButton: 'tConversation__messageClearButton',
    messageThumbUpButton: 'tConversation__messageThumbUpButton',
    messageThumbDownButton: 'tConversation__messageThumbDownButton',
    shareButton: 'tConversation__conversationShareButton',
    downloadButton: 'tConversation__conversationDownloadButton',
    downloadCSVButton: 'tConversation__conversationDownloadCSVButton',
    downloadPNGButton: 'tConversation__conversationDownloadPNGButton',
    restartButton: 'tConversation__conversationRestartButton',
    feedbackButton: 'tConversation__conversationFeedbackButton',
  },
  toolboxSimulator: {
    backButton: 'tToolboxSimulator__backButton',
    editMetadataButton: 'tToolboxSimulator__editMetadataButton',
    addJobButton: 'tToolboxSimulator__addJobButton',
    exportSimulationButton: 'tToolboxSimulator__exportSimulationButton',
    jobTypeSelect: 'tToolboxSimulator__jobTypeSelect',
    jobRemoveButton: 'tToolboxSimulator__jobRemoveButton',
    entitySelect: 'tToolboxSimulator__entitySelect',
    entitySimSelect: 'tToolboxSimulator__entitySimSelect',
    addQuestionButton: 'tToolboxSimulator__addQuestionButton',
    generateQuestionsButton: 'tToolboxSimulator__generateQuestionsButton',
    saveAndRunButton: 'tToolboxSimulator__saveAndRunButton',
    questionInput: 'tToolboxSimulator__questionInput',
    removeQuestionButton: 'tToolboxSimulator__removeQuestionButton',
    addTurnButton: 'tToolboxSimulator__addTurnButton',
    expectedAnswerInput: 'tToolboxSimulator__expectedAnswerInput',
    questionGeneratorSourceSelect:
      'tToolboxSimulator__questionGeneratorSourceSelect',
    questionGeneratorAccountText:
      'tToolboxSimulator__questionGeneratorAccountText',
    questionGeneratorTimeRangeSelect:
      'tToolboxSimulator__questionGeneratorTimeRangeSelect',
    questionGeneratorSkillIdsText:
      'tToolboxSimulator__questionGeneratorSkillIdsText',
    questionGeneratorConsumerKeywordsText:
      'tToolboxSimulator__questionGeneratorConsumerKeywordsText',
    questionGeneratorAgentKeywordsText:
      'tToolboxSimulator__questionGeneratorAgentKeywordsText',
    questionGeneratorConvIdsText:
      'tToolboxSimulator__questionGeneratorConvIdsText',
    questionGeneratorCancelButton:
      'tToolboxSimulator__questionGeneratorCancelButton',
    questionGeneratorSubmitButton:
      'tToolboxSimulator__questionGeneratorSubmitButton',
  },
  toolboxTranscriptAnalysis: {
    backButton: 'tToolboxTranscriptAnalysis__backButton',
    displayNameField: 'tToolboxTranscriptAnalysis__displayNameField',
    accountIdField: 'tToolboxTranscriptAnalysis__accountIdField',
  },
  conversations: {},
  libraryConversations: {
    exportButton: 'tLibraryConversations__exportButton',
    filterOwner: 'tLibraryConversations__filterOwner',
    filterBot: 'tLibraryConversations__filterBot',
    filterAccount: 'tLibraryConversations__filterAccount',
    filterDateStart: 'tLibraryConversations__filterDateStart',
    filterDateEnd: 'tLibraryConversations__filterDateEnd',
    filterSource: 'tLibraryConversations__filterSource',
    filterId: 'tLibraryConversations__filterId',
    filterLabel: 'tLibraryConversations__filterLabel',
    filterHasFeedback: 'tLibraryConversations__filterHasFeedback',
    filterHasSuggestions: 'tLibraryConversations__filterHasSuggestions',
    filterIncludeMessages: 'tLibraryConversations__filterIncludeMessages',
    filterIncludeSuggestions: 'tLibraryConversations__filterIncludeSuggestions',
  },
  settings: {},
};
