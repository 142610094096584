import {
  DEFAULT_ACCOUNT_ID,
  DEFAULT_USER_ID,
  HOST_MAP,
} from '@/constants/constants';
import type { App } from 'vue';

export function registerPendo(app: App) {
  // const { zone, phase, grid, datacenter } = HOST_MAP[location.hostname];

  app.config.globalProperties.window = window;

  // app.config.globalProperties.window.pendo.initialize({
  //   visitor: {
  //     id: DEFAULT_USER_ID,
  //   },
  //   account: {
  //     id: DEFAULT_ACCOUNT_ID,
  //     zone,
  //     phase,
  //     datacenter,
  //     grid,
  //   },
  // });
}
