<template>
  <v-snackbar
    :data-testid="TEST_IDS.common.snackbar"
    v-model="show"
    :timeout="timeout"
  >
    <div>{{ message }}</div>
    <div v-if="caption" class="text-caption pt-2">
      {{ caption }}
    </div>
    <template v-slot:actions>
      <v-btn color="pink" variant="text" @click="show = false"> Close </v-btn>
    </template>
  </v-snackbar>
</template>

<script lang="ts" setup>
import { useNotifyStore } from '@/store/notify';
import { ref } from 'vue';
import { TEST_IDS } from '@/constants/tests';

const show = ref(false);
const message = ref('');
const timeout = ref(4000);
const caption = ref<string | undefined>();

const store = useNotifyStore();

store.$onAction(({ name, after }) => {
  if (name === 'showSnackbar') {
    after((data) => {
      message.value = data.message;
      caption.value = data.caption;
      timeout.value = data.timeout;
      show.value = true;
    });
  }
});
</script>
