import {
  IAgentWidgetChatInfo,
  IAgentWidgetChatInfoDialog,
  IAgentWidgetChatTranscriptLine,
} from '@/interfaces/interfaces';
import HelperService from '@/services/HelperService';
import { AGENT_WIDGET_SOURCE } from './constants';

export class AgentWidgetChatInfo implements IAgentWidgetChatInfo {
  rtSessionId: string; // The conversation ID
  accountId: string; // The account ID
  chatRequestedTime: number; // The time the conversation was started
  chatStartTime: number; // The time the conversation started
  sessionStartTime: number; // N/A
  chatStartUrl: string; // N/A
  spectatedEngagement: boolean; // True if the engagement is spectated (and the logged in agent is not the assigned agent)
  isMessaging: boolean; // True if a messaging conversation
  chatSkill: string; // The conversation skill
  dialogs: IAgentWidgetChatInfoDialog;

  constructor(private opts?: Partial<IAgentWidgetChatInfo>) {
    this.rtSessionId = opts?.rtSessionId ?? '';
    this.accountId = opts?.accountId ?? '';
    this.chatRequestedTime = opts?.chatRequestedTime ?? Date.now();
    this.chatStartTime = opts?.chatStartTime ?? Date.now();
    this.sessionStartTime = opts?.sessionStartTime ?? Date.now();
    this.chatStartUrl = opts?.chatStartUrl ?? 'https://www.liveperson.com';
    this.spectatedEngagement = opts?.spectatedEngagement ?? false;
    this.isMessaging = opts?.isMessaging ?? true;
    this.chatSkill = opts?.chatSkill ?? 'undefined';
    this.dialogs = opts?.dialogs ?? {};
    delete this.opts;
  }
}

export class AgentWidgetChatLine implements IAgentWidgetChatTranscriptLine {
  id: string; // The serial ID of the chat line
  type: string; // show always be 'line'
  by: string; // The name of the visitor/agent who sent the line.	Can also be 'info'
  source: AGENT_WIDGET_SOURCE; // Is the chat line from 'visitor' or 'agent' or 'system'
  subType: 'REGULAR'; // Should always be 'REGULAR'.	May include other types of content in the future
  systemMessageId: string; // If it's a system message, this is the ID of the system message. If not, this property doesn't exist	number
  text: string; // Contains the text/html content of the chat line
  textType: string; // Can be 'plain' for plain text or 'html' for html content.	All agent lines are html content.
  time: string; // String representation of the time the line was added
  clientProperties: any; // containing client properties	object

  constructor(private opts?: Partial<IAgentWidgetChatTranscriptLine>) {
    this.id = opts?.id ?? HelperService.uuid();
    this.type = opts?.type ?? 'line';
    this.by = opts?.by ?? 'info';
    this.source = opts?.source ?? AGENT_WIDGET_SOURCE.VISITOR;
    this.subType = opts?.subType ?? 'REGULAR';
    this.systemMessageId = opts?.systemMessageId ?? HelperService.uuid();
    this.text = opts?.text ?? '';
    this.textType = opts?.textType ?? 'plain';
    this.time = opts?.time ?? new Date().toISOString();
    this.clientProperties = opts?.clientProperties ?? {};
    delete this.opts;
  }
}
