import { toRaw } from 'vue';
import {
  ACTION_KEYS,
  API_ROUTES,
  CONVERSATION_SOURCE,
  FILTER_CREATED_BY,
  STORAGE_KEYS,
} from '@/constants/constants';
import {
  FLOW_FILTER_TYPE,
  FLOW_NODE_BASE_TYPE,
  FLOW_NODE_BASE_TYPES,
  FLOW_NODE_PARAM_TYPE,
  FLOW_NODE_TEMPLATE_GROUP,
  FLOW_NODE_TYPE,
  FLOW_USE_CASE,
} from '@/constants/flows.constants';
import {
  IFlow,
  IFlowNode,
  IFlowNodeParamData,
  IFlowNodeTemplate,
  INodePosition,
  IFlowNodeBaseTypeDataWithTemplates,
  IFlowEdge,
  IFlowNodesEdges,
  IFlowFilters,
  IFlowResponse,
  IFlowListResponse,
  IFlowResponseData,
  IFlowGuidedRoutingIntentEvalResults,
  IFlowFiltersLocal,
} from '@/interfaces/flows.interfaces';
import { FlowCbTemplate } from '@/interfaces/flowCbTemplate.interface';
import { IMessage, IColor, IConversation } from '@/interfaces/interfaces';
import ApiService from '@/services/ApiService';
import ErrorService from '@/services/ErrorService';
import HelperService from '@/services/HelperService';
import { Connection, Edge, GraphNode } from '@vue-flow/core';
import { defineStore } from 'pinia';
import { useNotifyStore } from './notify';
import {
  CreateFlowDTO,
  GetModelResponseDTO,
  GuidedRoutingEvalDTO,
  UpdateFlowDTO,
  ValidateFlowDTO,
} from '@/interfaces/dtos';
import FileService from '@/services/FileService';
import { CreateConversationDTO } from '@/interfaces/dtos';
import { useUserStore } from './user';
import { from, lastValueFrom, mergeMap, reduce } from 'rxjs';
import { useAppStore } from './app';

export interface IFlowsState {
  loaded: boolean;
  loadingFlows: boolean;
  loadingFlowsStop: boolean;
  loadingTemplates: boolean;
  loadingFlowCbTemplate: boolean;
  loadedNodeTemplates: boolean;
  loadedUseCaseTemplates: boolean;
  loadedFlowCbTemplate: boolean;
  flowStateBuffer: Record<string, IFlowNodesEdges[]>;
  flowNodeTemplateEntities: Record<string, IFlowNodeTemplate>;
  flowUseCaseTemplates: Record<FLOW_USE_CASE, IFlow> | null;
  flowCbTemplate: FlowCbTemplate | null;
  flowEntities: Record<string, IFlowResponseData>;
  flowIds: string[];
  selectedFlowId: string | null;
  isDirty: boolean;
  isDialogOpen: boolean;
  autoConnect: boolean;
  filters: IFlowFilters;
  filtersLocal: IFlowFiltersLocal;
  page: number;
  pageIds: string[];
}

export const useFlowsStore = defineStore('flows', {
  state: (): IFlowsState => ({
    loaded: false,
    loadingFlows: false,
    loadingFlowsStop: false,
    loadingTemplates: false,
    loadingFlowCbTemplate: false,
    loadedNodeTemplates: false,
    loadedUseCaseTemplates: false,
    loadedFlowCbTemplate: false,
    flowStateBuffer: {},
    flowNodeTemplateEntities: {},
    flowUseCaseTemplates: null,
    flowCbTemplate: null,
    flowEntities: {},
    flowIds: [],
    selectedFlowId: null,
    isDirty: false,
    isDialogOpen: false,
    autoConnect:
      (localStorage.getItem(STORAGE_KEYS.AUTO_CONNECT) ?? 'true') === 'true',
    filters: {
      created_by: FILTER_CREATED_BY.ANY,
      account_id: undefined,
      flow_type: undefined,
      scroll_id: undefined,
      scroll_amount: 100,
    },
    filtersLocal: {
      keyword: undefined,
    },
    page: 0,
    pageIds: [],
  }),
  getters: {
    loading(state) {
      return state.loadingFlows || state.loadingTemplates;
    },
    canUndoSelectedFlow(state) {
      return !!state.flowStateBuffer[state.selectedFlowId ?? '']?.length;
    },
    baseTypeColors(): Map<string, IColor> {
      return HelperService.getColors(this.flowNodeBaseTypes);
    },
    selectedFlow(state): IFlow | null {
      return state.selectedFlowId
        ? state.flowEntities[state.selectedFlowId]?.flow ?? null
        : null;
    },
    flowNodeTemplatesByBaseType(state) {
      return Object.entries(state.flowNodeTemplateEntities).reduce(
        (prev, [_, template]) => {
          prev[template.base_type] = prev[template.base_type] ?? [];
          prev[template.base_type].push(template);
          return prev;
        },
        {} as Record<FLOW_NODE_BASE_TYPE, IFlowNodeTemplate[]>,
      );
    },
    flowNodeBaseTypes(): string[] {
      return Object.keys(this.flowNodeTemplatesByBaseType);
    },
    flowNodeTemplates(): IFlowNodeBaseTypeDataWithTemplates[] {
      return FLOW_NODE_BASE_TYPES.map((baseType) => {
        const nodeTemplates =
          this.flowNodeTemplatesByBaseType[baseType.type] ?? [];
        return {
          ...baseType,
          nodeTemplates: nodeTemplates.filter(
            (template) => !template.hidden && !template.disabled,
          ),
        };
      });
    },
    flows(state) {
      return state.flowIds
        .map((id) => state.flowEntities[id].flow)
        .sort((a, b) => (b.created_at ?? 0) - (a.created_at ?? 0));
    },
    filteredFlows(): IFlow[] {
      if (!this.filtersLocal.keyword) return this.flows;

      return this.flows.filter((flow) => {
        const fields = [flow.id, flow.display_name];
        if (flow.description) {
          fields.push(flow.description);
        }
        if (flow.account_id) {
          fields.push(flow.account_id);
        }
        const textToSearch = fields.join('').toLowerCase();
        return textToSearch.includes(
          this.filtersLocal.keyword?.toLowerCase() ?? '',
        );
      });
    },
    templateFlows(): IFlow[] {
      return this.flows
        .filter((flow) => flow.template)
        .sort((a, b) => a.created_at - b.created_at)
        .sort((a, b) =>
          a.template_group === FLOW_NODE_TEMPLATE_GROUP.OFFICIAL ? -1 : 1,
        );
    },
    globalTemplateFlows(): IFlow[] {
      return this.templateFlows.filter(
        (flow) => flow.template_group === FLOW_NODE_TEMPLATE_GROUP.OFFICIAL,
      );
    },
    hasConversationCloudNode(): boolean {
      return this.selectedFlow
        ? !!this.selectedFlow.nodes.find(
            (node) => node.type === FLOW_NODE_TYPE.ConversationCloudChain,
          )
        : false;
    },
    flowConversationCloudSkillId(): string {
      return this.selectedFlow && this.hasConversationCloudNode
        ? this.selectedFlow.nodes.find(
            (node) => node.type === FLOW_NODE_TYPE.ConversationCloudChain,
          )?.data['skill_id'].value
        : '';
    },
    hasContextualMemoryNode(): boolean {
      return this.selectedFlow
        ? !!this.selectedFlow.nodes.find(
            (node) => node.type === FLOW_NODE_TYPE.LPContextualMemory,
          )
        : false;
    },
  },
  actions: {
    resetFlows() {
      this.$reset();
    },
    toggleAutoConnect(enabled: boolean) {
      this.autoConnect = enabled;
      localStorage.setItem(
        STORAGE_KEYS.AUTO_CONNECT,
        enabled ? 'true' : 'false',
      );
    },
    saveState(flowId: string, filterLostEdges?: boolean) {
      const flow = this.flowEntities[flowId]?.flow;
      if (!flow) throw Error(`Flow not found: ${flowId}`);
      if (filterLostEdges) {
        flow.edges = this.filterLostEdges(flow.edges, flow.nodes);
      }
      this.flowStateBuffer[flowId] = this.flowStateBuffer[flowId] ?? [];
      this.flowStateBuffer[flowId].push({
        nodes: JSON.parse(JSON.stringify(flow.nodes)),
        edges: JSON.parse(JSON.stringify(flow.edges)),
      });
    },
    undo(flowId: string) {
      const lastState = this.flowStateBuffer[flowId]?.pop();
      if (!lastState) return;
      const flow = this.flowEntities[flowId]?.flow;
      if (!flow) throw Error(`Flow not found: ${flowId}`);
      flow.nodes = lastState.nodes;
      flow.edges = lastState.edges;
    },
    selectFlow(flowId: string | null) {
      this.selectedFlowId = flowId;
      this.isDirty = false;
      this.isDialogOpen = false;
    },
    generateFlow() {
      const flow: IFlow = {
        id: `Temp-${HelperService.uuid(6)}`,
        display_name: HelperService.generateUniqueName(),
        public: false,
        template: false,
        nodes: [],
        edges: [],
        created_at: 0,
        updated_at: 0,
        created_by: '',
        updated_by: '',
        version: 1.0,
        agent_widget_enabled: false,
        agent_widget_skills: [],
      };
      return flow;
    },
    async duplicateFlow(flowId: string) {
      const { defaultAccountId } = useUserStore();
      const flowData = this.flowEntities[flowId];
      if (!flowData) throw Error(`Flow not found: ${flowId}`);

      if (flowData.partial) {
        await this.getFlowById(flowId);
      }

      const flowCopy = this.generateUniqueIds(this.flowEntities[flowId].flow);

      const displayName = `${flowCopy.display_name} [copy]`.substring(0, 35);

      const dto: CreateFlowDTO = {
        nodes: flowCopy.nodes,
        edges: this.filterLostEdges(flowCopy.edges, flowCopy.nodes),
        public: defaultAccountId ? true : false,
        template: false,
        account_id: defaultAccountId,
        display_name: displayName,
        use_case: flowCopy.use_case,
        use_case_mapping: flowCopy.use_case_mapping,
        version: flowCopy.version,
        agent_widget_enabled: flowCopy.agent_widget_enabled,
        agent_widget_skills: flowCopy.agent_widget_skills,
      };

      return this.createFlow(dto);
    },
    updateFlowNodesPosition(flowId: string, nodes: GraphNode[]) {
      this.saveState(flowId);

      const flow = this.flowEntities[flowId]?.flow;
      if (!flow) throw Error(`Flow not found: ${flowId}`);
      nodes.forEach((update) => {
        const node = flow.nodes.find((node) => node.id === update.id);
        if (!node) {
          return console.error(`Node not found: ${update.id}`);
        }
        node.position = update.position;
      });
      this.isDirty = true;
    },
    async copyFlowNodesFromTemplate(
      flowId: string,
      templateFlowId: string,
      position: INodePosition,
    ) {
      this.saveState(flowId, true);

      const flow = this.flowEntities[flowId]?.flow;
      if (!flow) throw Error(`Flow not found: ${flowId}`);
      const templateFlow = this.flowEntities[templateFlowId];
      if (!templateFlow)
        throw Error(`Template flow not found: ${templateFlowId}`);

      if (templateFlow.partial) {
        await this.getFlowById(templateFlowId);
      }

      const flowCopy = this.generateUniqueIds(
        this.flowEntities[templateFlowId].flow,
      );

      const nodes: IFlowNode[] = flowCopy.nodes.map((node) => ({
        ...node,
        position: {
          x: node.position.x + position.x,
          y: node.position.y + position.y,
        },
      }));

      flow.nodes.push(...nodes);
      flow.edges.push(...flowCopy.edges);
      this.isDirty = true;
      return nodes;
    },
    addFlowNode(flowId: string, nodeType: string, position: INodePosition) {
      this.saveState(flowId, true);

      const template = this.flowNodeTemplateEntities[nodeType];
      if (!template) {
        throw Error(`Template not found for node type: ${nodeType}`);
      }
      const flowNode: IFlowNode = {
        id: `${nodeType}-${HelperService.uuid(6)}`,
        type: nodeType,
        base_type: template.base_type,
        position,
        disabled: false,
        data: Object.entries(template.params).reduce((prev, [key, param]) => {
          prev[key] = {
            value: param.value,
            show: param.show,
          };
          return prev;
        }, {} as Record<string, IFlowNodeParamData>),
      };
      const flow = this.flowEntities[flowId]?.flow;
      if (!flow) throw Error(`Flow not found: ${flowId}`);
      flow.edges = this.filterLostEdges(flow.edges, flow.nodes);
      flow.nodes.push(flowNode);
      this.isDirty = true;
      return flowNode;
    },
    removeFlowNodes(flowId: string, ids: Set<string>) {
      this.saveState(flowId, true);

      const flow = this.flowEntities[flowId]?.flow;
      if (!flow) throw Error(`Flow not found: ${flowId}`);
      flow.nodes = flow.nodes.filter((e) => !ids.has(e.id));
      flow.edges = flow.edges.filter(
        (e) => !ids.has(e.source) || !ids.has(e.target),
      );
      flow.edges = this.filterLostEdges(flow.edges, flow.nodes);
      this.isDirty = true;
    },
    duplicateFlowNode(flowId: string, id: string) {
      this.saveState(flowId, true);

      const flow = this.flowEntities[flowId]?.flow;
      if (!flow) throw Error(`Flow not found: ${flowId}`);
      const node = flow.nodes.find((node) => node.id === id);
      if (node) {
        const newNode: IFlowNode = {
          ...JSON.parse(JSON.stringify(node)),
          id: `${node.type}-${HelperService.uuid(6)}`,
          position: {
            x: node.position.x + 20,
            y: node.position.y + 20,
          },
        };
        flow.nodes.push(newNode);
        flow.edges = this.filterLostEdges(flow.edges, flow.nodes);
        this.isDirty = true;
      }
    },
    toggleDisabledFlowNodes(flowId: string, ids: string[], state: boolean) {
      this.saveState(flowId, true);
      const flow = this.flowEntities[flowId]?.flow;
      if (!flow) throw Error(`Flow not found: ${flowId}`);
      const nodes = new Map(flow.nodes.map((node) => [node.id, node]));
      ids.forEach((id) => {
        const node = nodes.get(id);
        if (node) {
          node.disabled = state;

          flow.edges = flow.edges.map((edge) => {
            if (edge.source !== node.id && edge.target !== node.id) {
              return edge;
            }

            const sourceNode = nodes.get(edge.source);
            const targetNode = nodes.get(edge.target);

            edge.disabled =
              sourceNode?.disabled || targetNode?.disabled || node.disabled;

            return edge;
          });
        }
      });
    },
    addFlowEdges(flowId: string, edges: (Edge | Connection)[]) {
      this.saveState(flowId);

      const flow = this.flowEntities[flowId]?.flow;
      if (!flow) throw Error(`Flow not found: ${flowId}`);
      const nodes = new Map(flow.nodes.map((node) => [node.id, node]));

      const newEdges = edges.map((edge) => {
        const isDisabled =
          nodes.get(edge.source)?.disabled ||
          nodes.get(edge.target)?.disabled ||
          false;
        return {
          id: `Edge-${HelperService.uuid(6)}`,
          source: edge.source,
          target: edge.target,
          sourceHandle: edge.sourceHandle,
          targetHandle: edge.targetHandle,
          disabled: isDisabled,
        };
      });
      flow.edges.push(...newEdges);
      this.isDirty = true;
    },
    removeFlowEdges(flowId: string, ids: Set<string>) {
      this.saveState(flowId);

      const flow = this.flowEntities[flowId]?.flow;
      if (!flow) throw Error(`Flow not found: ${flowId}`);
      flow.edges = flow.edges.filter((e) => !ids.has(e.id));
      this.isDirty = true;
    },
    updateFlowEdge(flowId: string, edgeId: string, edge: Edge) {
      this.saveState(flowId);

      const flow = this.flowEntities[flowId]?.flow;
      if (!flow) throw Error(`Flow not found: ${flowId}`);
      const index = flow.edges.findIndex((e) => e.id === edgeId);
      flow.edges[index] = {
        id: edge.id,
        source: edge.source,
        target: edge.target,
        sourceHandle: edge.sourceHandle,
        targetHandle: edge.targetHandle,
        disabled: flow.edges[index]?.disabled ?? false,
      };
      this.isDirty = true;
    },
    updateFlowNodeParamsData(
      flowId: string,
      nodeId: string,
      updates: { [key: string]: Partial<IFlowNodeParamData> },
    ) {
      this.saveState(flowId);

      const flow = this.flowEntities[flowId]?.flow;
      if (!flow) throw Error(`Flow not found: ${flowId}`);
      const index = flow.nodes.findIndex((node) => node.id === nodeId);
      const node = flow.nodes[index];
      const edgesToRemove = new Set<string>();

      Object.entries(updates).forEach(([key, data]) => {
        const param = this.flowNodeTemplateEntities[node.type]?.params[key];
        node.data[key] = { ...node.data[key], ...data };
        if (
          param?.param_type === FLOW_NODE_PARAM_TYPE.NODE &&
          !node.data[key].show
        ) {
          edgesToRemove.add(`${node.id}${key}`);
        }
      });
      if (edgesToRemove.size) {
        flow.edges = flow.edges.filter(
          (edge) => !edgesToRemove.has(`${edge.target}${edge.targetHandle}`),
        );
      }
      this.isDirty = true;
    },
    async getFlowNodeTemplates() {
      const key = ACTION_KEYS.GET_FLOW_NODE_TEMPLATES;
      try {
        this.loadingTemplates = true;
        const url = API_ROUTES.FLOWS_NODE_TEMPLATES();
        const { data } = await ApiService.get<
          Record<string, IFlowNodeTemplate>
        >(url, key);
        this.$patch({
          flowNodeTemplateEntities: data,
          loadedNodeTemplates: true,
        });
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loadingTemplates = ApiService.hasInflightRequest(key);
      }
    },
    async getFlowCBTemplate() {
      const key = ACTION_KEYS.GET_FLOW_CB_TEMPLATE;
      try {
        this.loadingFlowCbTemplate = true;
        const flowTemplateJson = 'aistudio-flow-cb-bot-template.json';
        const response = await ApiService.get<FlowCbTemplate>(
          `assets/${flowTemplateJson}`,
          key,
          {},
          undefined,
          true,
        );

        this.$patch({
          flowCbTemplate: response.data,
          loadedFlowCbTemplate: true,
        });
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loadingFlowCbTemplate = ApiService.hasInflightRequest(key);
      }
    },
    async getFlowUseCaseTemplates() {
      const { region } = useAppStore();
      const key = ACTION_KEYS.GET_FLOW_USE_CASE_TEMPLATES;
      try {
        this.loadingTemplates = true;
        // const url = API_ROUTES.FLOWS_NODE_TEMPLATES();
        const cacheBust = Date.now();
        const CONCURRENCY = 5;
        const data = await lastValueFrom(
          from(Object.values(FLOW_USE_CASE)).pipe(
            mergeMap(async (useCase) => {
              const useCaseJson = `use_case-${useCase}-${region}.json`;
              const response = await ApiService.get<IFlow>(
                `assets/${useCaseJson}`,
                `${key}${useCase}`,
                {
                  random: cacheBust,
                },
                undefined,
                true,
              );
              return { useCase, flow: response.data };
            }, CONCURRENCY),
            reduce((prev, curr) => {
              prev[curr.useCase] = curr.flow;
              return prev;
            }, {} as Record<FLOW_USE_CASE, IFlow>),
          ),
        );

        this.$patch({
          flowUseCaseTemplates: data,
          loadedUseCaseTemplates: true,
        });
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loadingTemplates = ApiService.hasInflightRequest(key);
      }
    },
    stopLoadingFlows() {
      ApiService.cancelByKey(ACTION_KEYS.GET_FLOWS);
      this.$patch({
        loadingFlowsStop: true,
        loadingFlows: false,
      });
    },
    async getAllFlows() {
      this.resetPages();
      let hasMore = true;

      while (hasMore) {
        if (this.loadingFlowsStop) break;
        const result = await this.getFlowsByPage(this.pageIds.length);
        hasMore =
          (result?.flows?.length ?? 0) >= this.filters.scroll_amount - 1;
      }
    },
    async getFlowsByPage(page: number) {
      const update: Partial<IFlowFilters> = {
        scroll_id: this.pageIds[page - 1] ?? undefined,
      };
      this.updateFilters(update);
      return await this.getFlowsByFilter();
    },
    async getFlowsByFilter() {
      this.loadingFlows = true;
      const actionKey = ACTION_KEYS.GET_FLOWS;
      try {
        const url = API_ROUTES.FLOWS();
        const { data } = await ApiService.get<IFlowListResponse>(
          url,
          actionKey,
          this.filters,
        );
        const ids: string[] = [];
        const entities: Record<string, IFlowResponseData> =
          data.flows?.reduce((prev, item) => {
            ids.push(item.flow.id);
            prev[item.flow.id] = { ...item, partial: true };
            return prev;
          }, {} as Record<string, IFlowResponseData>) ?? {};

        if (ids.length) {
          this.pageIds = Array.from(new Set([...this.pageIds, ...ids]));
        }
        this.$patch({
          flowEntities: {
            ...this.flowEntities,
            ...entities,
          },
          flowIds: Array.from(new Set([...this.flowIds, ...ids])),
          loaded: true,
        });
        return data;
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loadingFlows = ApiService.hasInflightRequest(actionKey);
      }
    },
    async getFlowById(id: string) {
      this.loadingFlows = true;
      const actionKey = ACTION_KEYS.GET_FLOW;
      try {
        const url = API_ROUTES.FLOWS_BY_ID(id);
        const { data } = await ApiService.get<IFlowResponse>(url, actionKey);
        let ids = this.flowIds;
        if (!this.flowEntities[data.flow.id]) {
          ids = this.flowIds.concat(data.flow.id);
        }
        this.$patch({
          flowEntities: {
            ...this.flowEntities,
            [data.flow.id]: {
              ...data,
              partial: false,
            },
          },
          flowIds: ids,
        });
        return data;
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loadingFlows = ApiService.hasInflightRequest(actionKey);
      }
    },
    async createFlow(body: CreateFlowDTO, tempId?: string) {
      const { showSnackbar } = useNotifyStore();
      const actionKey = ACTION_KEYS.CREATE_FLOW;
      this.loadingFlows = true;

      try {
        const url = API_ROUTES.FLOWS();
        body.edges = this.filterLostEdges(body.edges, body.nodes);
        const { data } = await ApiService.post<IFlowResponse>(
          url,
          body,
          actionKey,
        );
        this.$patch({
          flowIds: [
            ...this.flowIds.filter((id) => id !== tempId),
            data.flow.id,
          ],
          flowEntities: {
            ...this.flowEntities,
            [data.flow.id]: {
              ...data,
              partial: false,
            },
          },
          isDirty: false,
        });
        showSnackbar(`Flow created: ${data.flow.id}`);
        return data.flow;
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loadingFlows = ApiService.hasInflightRequest(actionKey);
      }
    },
    async updateFlow(body: UpdateFlowDTO, id: string) {
      const { showSnackbar } = useNotifyStore();
      const actionKey = `${ACTION_KEYS.UPDATE_FLOW}${id}`;
      this.loadingFlows = true;

      try {
        const url = API_ROUTES.FLOWS_BY_ID(id);
        const flow = this.flowEntities[id];
        if (!flow) throw Error(`Flow not found: ${id}`);

        const { data } = await ApiService.put<IFlowResponse>(
          url,
          body,
          actionKey,
          {
            'If-Match': flow.etag,
          },
        );
        this.$patch({
          flowEntities: {
            ...this.flowEntities,
            [data.flow.id]: {
              ...data,
              partial: false,
            },
          },
          isDirty: false,
        });
        showSnackbar(`Flow updated: ${id}`);
        return this.flowEntities[id].flow;
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loadingFlows = ApiService.hasInflightRequest(actionKey);
      }
    },
    async updateSelectedFlow() {
      if (!this.selectedFlow) {
        return;
      }

      const body: UpdateFlowDTO = {
        display_name: this.selectedFlow.display_name,
        public: this.selectedFlow.public,
        nodes: this.selectedFlow.nodes,
        edges: this.filterLostEdges(
          this.selectedFlow.edges,
          this.selectedFlow.nodes,
        ),
        account_id: this.selectedFlow.account_id,
      };
      await this.updateFlow(body, this.selectedFlow.id);
    },
    async deleteFlow(flowId: string) {
      const { showSnackbar } = useNotifyStore();
      const actionKey = ACTION_KEYS.DELETE_FLOW;
      this.loadingFlows = true;

      try {
        const url = API_ROUTES.FLOWS_BY_ID(flowId);
        const flow = this.flowEntities[flowId];
        if (!flow) throw Error(`Flow not found: ${flowId}`);
        const { data } = await ApiService.delete<string>(
          url,
          actionKey,
          undefined,
          undefined,
          {
            'If-Match': flow.etag,
          },
        );
        const { [data]: toRemove, ...flowEntities } = this.flowEntities;
        const index = this.flowIds.findIndex((id) => id === toRemove.flow.id);
        this.$patch({
          flowIds: [
            ...this.flowIds.slice(0, index),
            ...this.flowIds.slice(index + 1),
          ],
          flowEntities: {
            ...flowEntities,
          },
          isDirty: false,
        });
        if (this.selectedFlowId === flowId) {
          this.selectedFlowId = null;
        }
        showSnackbar(`Flow deleted: ${flowId}`);
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loadingFlows = ApiService.hasInflightRequest(actionKey);
      }
    },
    async validateFlow(flowId: string) {
      const { showSnackbar } = useNotifyStore();
      const actionKey = ACTION_KEYS.VALIDATE_FLOW;
      this.loadingFlows = true;

      try {
        const flow = this.flowEntities[flowId]?.flow;
        if (!flow) throw Error(`Flow not found: ${flowId}`);
        flow.edges = this.filterLostEdges(flow.edges, flow.nodes);
        const body: ValidateFlowDTO = {
          flow: {
            id: flow.id,
            account_id: flow.account_id,
            nodes: flow.nodes,
            edges: flow.edges,
          },
        };

        const url = API_ROUTES.FLOWS_VALIDATE();
        await ApiService.post<boolean>(url, body, actionKey);

        showSnackbar(`✅ Flow is valid`);
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loadingFlows = ApiService.hasInflightRequest(actionKey);
      }
    },
    filterLostEdges(edges: IFlowEdge[], nodes: IFlowNode[]) {
      // TODO: figure out why sometimes edges arent being removed
      const nodeMap = nodes.reduce((prev, node) => {
        prev.set(node.id, node);
        return prev;
      }, new Map<string, IFlowNode>());
      return edges.filter((edge) => {
        return nodeMap.has(edge.source) && nodeMap.has(edge.target);
      });
    },
    async startFlowConversation(body: CreateConversationDTO) {
      const actionKey = ACTION_KEYS.CREATE_CONVERSATION;

      try {
        this.loadingFlows = true;

        const url = API_ROUTES.CONVERSATIONS();
        const { data } = await ApiService.post<IConversation>(
          url,
          body,
          actionKey,
        );
        return data;
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loadingFlows = ApiService.hasInflightRequest(actionKey);
      }
    },
    async getFlowConversation(id: string) {
      const actionKey = ACTION_KEYS.GET_CONVERSATION;

      try {
        this.loadingFlows = true;

        const url = API_ROUTES.CONVERSATIONS_BY_ID(id);
        const { data } = await ApiService.get<IConversation>(url, actionKey);
        return data;
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loadingFlows = ApiService.hasInflightRequest(actionKey);
      }
    },
    async evaluteGuidedRoutingIntent(
      flowId: string,
      name: string,
      description: string,
      simplified: boolean,
    ) {
      const actionKey = ACTION_KEYS.EVALUATE_GUIDED_ROUTING;

      const flow = this.flowEntities[flowId]?.flow;
      if (!flow) throw Error(`Flow not found: ${flowId}`);
      const dto: GuidedRoutingEvalDTO = {
        flow: {
          id: flow.id,
          account_id: flow.account_id,
          nodes: flow.nodes,
          edges: this.filterLostEdges(flow.edges, flow.nodes),
        },
        intent: {
          name,
          description,
        },
        simplified,
        details: false,
      };

      const url = API_ROUTES.GUIDED_ROUTING_EVAL();

      try {
        this.loadingFlows = true;

        const { data } =
          await ApiService.post<IFlowGuidedRoutingIntentEvalResults>(
            url,
            dto,
            actionKey,
          );
        return data;
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loadingFlows = ApiService.hasInflightRequest(actionKey);
      }
    },
    async testFlow(
      flowId: string,
      convId: string,
      message: IMessage,
      messages: IMessage[],
      save: boolean = true,
    ) {
      const actionKey = ACTION_KEYS.TEST_FLOW;

      try {
        this.loadingFlows = true;

        const flow = this.flowEntities[flowId]?.flow;
        if (!flow) throw Error(`Flow not found: ${flowId}`);

        const body: GetModelResponseDTO = {
          text: message.text,
          conv_id: convId,
          source: CONVERSATION_SOURCE.AI_DOJO,
          messages: messages,
          flow: {
            id: flow.id,
            account_id: flow.account_id,
            nodes: flow.nodes,
            edges: this.filterLostEdges(flow.edges, flow.nodes),
          },
          save_conv: save,
          save_answer: save,
          debug: true,
        };

        const url = API_ROUTES.FLOWS_RESPONSE();

        const { data } = await ApiService.post<IMessage[]>(
          url,
          body,
          actionKey,
        );

        return data;
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loadingFlows = ApiService.hasInflightRequest(actionKey);
      }
    },
    async exportFlow(flowId: string) {
      const flowData = this.flowEntities[flowId];
      if (!flowData) throw Error(`Flow not found: ${flowId}`);

      if (flowData.partial) {
        await this.getFlowById(flowId);
      }

      const flow = this.flowEntities[flowId].flow;
      FileService.downloadAsJson(flow, flow.id, 'flow');
    },
    generateUniqueIds(flow: IFlow) {
      const flowCopy = structuredClone(toRaw(flow));
      const newIdMap = flowCopy.nodes.reduce((prev, node) => {
        prev.set(node.id, `${node.type}-${HelperService.uuid(6)}`);
        return prev;
      }, new Map<string, string>());

      const edges = flowCopy.edges.map((edge) => ({
        ...edge,
        id: `Edge-${HelperService.uuid(6)}`,
        source: newIdMap.get(edge.source)!,
        target: newIdMap.get(edge.target)!,
      }));

      const nodes: IFlowNode[] = flowCopy.nodes.map((node) => ({
        ...node,
        id: newIdMap.get(node.id)!,
      }));

      const use_case_mapping = flowCopy.use_case_mapping;

      if (use_case_mapping && flowCopy.use_case) {
        const nodeKeys = use_case_mapping[flowCopy.use_case!];
        Object.keys(nodeKeys).forEach((key) => {
          const map_key = nodeKeys[key];

          if (typeof map_key === 'string') {
            nodeKeys[key] = newIdMap.get(map_key)!;
          } else {
            nodeKeys[key] = map_key.map((id) => newIdMap.get(id)!);
          }
        });
      }
      return {
        ...flowCopy,
        edges,
        nodes,
        use_case_mapping,
      };
    },
    importFlow(flow: IFlow) {
      const { defaultAccountId } = useUserStore();
      const flowCopy = this.generateUniqueIds(flow);

      const dto: CreateFlowDTO = {
        display_name: HelperService.generateUniqueName(),
        description: flowCopy.description,
        account_id: defaultAccountId,
        public: defaultAccountId ? true : false,
        template: false,
        nodes: flowCopy.nodes,
        edges: this.filterLostEdges(flowCopy.edges, flowCopy.nodes),
        use_case: flowCopy.use_case,
        use_case_mapping: flowCopy.use_case_mapping,
        version: flowCopy.version,
        agent_widget_enabled: flowCopy.agent_widget_enabled,
        agent_widget_skills: flowCopy.agent_widget_skills,
      };

      return this.createFlow(dto);
    },
    async createFlowFromTemplate(templateFlowId: string) {
      const { defaultAccountId } = useUserStore();
      const flowData = this.flowEntities[templateFlowId];
      if (!flowData) throw Error(`Template flow not found: ${templateFlowId}`);

      if (flowData.partial) {
        await this.getFlowById(templateFlowId);
      }

      const flowCopy = this.generateUniqueIds(
        this.flowEntities[templateFlowId].flow,
      );

      const dto: CreateFlowDTO = {
        display_name: HelperService.generateUniqueName(),
        public: defaultAccountId ? true : false,
        template: false,
        account_id: defaultAccountId,
        nodes: flowCopy.nodes,
        edges: this.filterLostEdges(flowCopy.edges, flowCopy.nodes),
        use_case: flowCopy.use_case,
        use_case_mapping: flowCopy.use_case_mapping,
        version: flowCopy.version,
        agent_widget_enabled: flowCopy.agent_widget_enabled,
        agent_widget_skills: flowCopy.agent_widget_skills,
      };

      return this.createFlow(dto);
    },
    async createFlowFromUseCase(useCase: FLOW_USE_CASE) {
      const { defaultAccountId } = useUserStore();
      const key = ACTION_KEYS.CREATE_FLOW;

      try {
        this.loadingFlows = true;

        if (!this.loadedUseCaseTemplates) {
          await this.getFlowUseCaseTemplates();
        }

        if (!this.flowUseCaseTemplates || !this.flowUseCaseTemplates[useCase]) {
          throw new Error(`Use case template not found: ${useCase}`);
        }

        const flowCopy = this.generateUniqueIds(
          this.flowUseCaseTemplates[useCase],
        );

        const dto: CreateFlowDTO = {
          display_name: HelperService.generateUniqueName(),
          public: defaultAccountId ? true : false,
          template: false,
          nodes: flowCopy.nodes,
          edges: this.filterLostEdges(flowCopy.edges, flowCopy.nodes),
          use_case: flowCopy.use_case,
          use_case_mapping: flowCopy.use_case_mapping,
          account_id: defaultAccountId,
          version: flowCopy.version,
          agent_widget_enabled: flowCopy.agent_widget_enabled,
          agent_widget_skills: flowCopy.agent_widget_skills,
        };

        return this.createFlow(dto);
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loadingFlows = ApiService.hasInflightRequest(key);
      }
    },
    async createFlowContextVariablePostProcessCode(flowId: string) {
      if (!this.loadedFlowCbTemplate) {
        await this.getFlowCBTemplate();
      }
      const flow = this.flowEntities[flowId].flow;

      const contextualMemoryNode = flow.nodes.find(
        (node) =>
          node.base_type === FLOW_NODE_BASE_TYPE.MEMORY &&
          node.type === FLOW_NODE_TYPE.LPContextualMemory,
      );
      if (!contextualMemoryNode) return '';

      const conversationVariablesKeyString: string =
        contextualMemoryNode.data.conversational_slot_key_list.value;

      if (!conversationVariablesKeyString) {
        return '';
      }

      const getConvVariablesCode = conversationVariablesKeyString
        .split(',')
        .map(
          (key) =>
            `var ${key} = jsonResponse[0].engagement_attributes.conversational_slots.${key};`,
        )
        .join('\n');

      const getSetCBVariablesCode = conversationVariablesKeyString
        .split(',')
        .map(
          (key) => `botContext.setBotVariable("${key}", ${key}, true, false);`,
        )
        .join('\n');

      const postProcessTemplate = this.flowCbTemplate?.conversationMessage.find(
        (item) => item.name === 'get_aistudio_response',
      )?.postProcessMessage;

      if (!postProcessTemplate) return '';

      return postProcessTemplate
        .replace('{get_conversation_slots_from_response}', getConvVariablesCode)
        .replace(
          '{set_bot_context_variables_from_slots}',
          getSetCBVariablesCode,
        );
    },
    async exportFlowForCb(flowId: string) {
      if (!this.loadedFlowCbTemplate) {
        await this.getFlowCBTemplate();
      }

      const flow = this.flowEntities[flowId].flow;
      const flowCbTemplateCopy = structuredClone(toRaw(this.flowCbTemplate));

      if (!flowCbTemplateCopy) return;

      // rename flow
      flowCbTemplateCopy.bot.name = `aiStudio Flow ${flow.display_name}`;

      // set ai studio url, project key, and flow id for template
      const aiStudioURL = `https://${location.hostname}`;

      flowCbTemplateCopy.globalFunctions.methods =
        flowCbTemplateCopy.globalFunctions.methods
          .replace('{aiStudioURL}', aiStudioURL)
          .replace('{aiStudioFlowID}', flowId);

      const studioResponseBlock = flowCbTemplateCopy.conversationMessage.find(
        (item) =>
          item.postProcessMessage?.includes(
            '{get_conversation_slots_from_response}',
          ) ||
          item.postProcessMessage?.includes(
            '{set_bot_context_variables_from_slots}',
          ),
      );
      if (!studioResponseBlock) return;

      studioResponseBlock.postProcessMessage =
        await this.createFlowContextVariablePostProcessCode(flowId);

      FileService.downloadAsJson(
        flowCbTemplateCopy,
        flowId,
        'aiStudio-CB',
        true,
      );
    },
    changePage(page: number) {
      this.page = page - 1;
    },
    resetPages() {
      this.$patch({
        flowEntities: {},
        flowIds: [],
        filters: {
          ...this.filters,
          scroll_id: undefined,
        },
        pageIds: [],
        page: 0,
        loadingFlowsStop: false,
      });
    },
    updateFilters(filters: Partial<IFlowFilters>) {
      this.$patch({ filters });
    },
  },
});
