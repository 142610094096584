import { Router } from 'vue-router';
import { PiniaLogger } from 'pinia-logger';
import { markRaw } from 'vue';
import pinia from '../store';

declare module 'pinia' {
  export interface PiniaCustomProperties {
    $router: Router;
  }
}

export function registerPinia(router: Router) {
  return pinia
    .use(
      PiniaLogger({
        expanded: false,
        disabled: import.meta.env.PROD,
      }),
    )
    .use(({ store }) => {
      store.$router = markRaw(router);
    });
}
