import {
  ACTION_KEYS,
  API_ROUTES,
  KNOWLEDGEBASE_SOURCE,
  STATUS,
} from '@/constants/constants';
import {
  IKnowledgebase,
  IKnowledgebaseItem,
  IKnowledgebaseItemExport,
  IKnowledgebaseTextItem,
  IKnowledgebaseTextItemForm,
  IKnowledgebasesFilters,
} from '@/interfaces/interfaces';
import ApiService from '@/services/ApiService';
import { defineStore } from 'pinia';
import ErrorService from '@/services/ErrorService';
import { useNotifyStore } from './notify';
import FileService from '@/services/FileService';
import HelperService from '@/services/HelperService';
import { KNOWLEDGE_FILTER_TYPE } from '@/constants/knowledge.constants';
import { useUserStore } from './user';

export interface IKnowledgebasesState {
  loading: boolean;
  loadingItems: boolean;
  loaded: boolean;
  selectedKnowledgebaseId: string | null;
  knowledgebaseEntities: Record<string, IKnowledgebase>;
  knowledgebaseIds: string[];
  filters: IKnowledgebasesFilters;
  kbItemEntitiesBySource: Record<string, Record<string, IKnowledgebaseItem>>;
  kbItemIdsBySource: Record<string, string[]>;
}

export const useKnowledgebasesStore = defineStore('knowledgebases', {
  state: (): IKnowledgebasesState => ({
    loading: false,
    loadingItems: false,
    loaded: false,
    selectedKnowledgebaseId: null,
    knowledgebaseEntities: {},
    knowledgebaseIds: [],
    filters: {
      keyword: null,
      type: KNOWLEDGE_FILTER_TYPE.ALL_KNOWLEDGE,
    },
    kbItemEntitiesBySource: {},
    kbItemIdsBySource: {},
  }),
  getters: {
    knowledgebases: (state) =>
      state.knowledgebaseIds
        .map((id) => state.knowledgebaseEntities[id])
        .sort((a, b) => (b.updated_at ?? 0) - (a.updated_at ?? 0)),
    selectedKnowledgebase: (state) =>
      state.selectedKnowledgebaseId
        ? state.knowledgebaseEntities[state.selectedKnowledgebaseId]
        : null,
    activeKnowledgebases(): IKnowledgebase[] {
      return this.knowledgebases.filter(
        (kb) =>
          kb.status === STATUS.IN_PROGRESS ||
          kb.status === STATUS.PENDING ||
          kb.kb_health_info?.kb_health_process_info.status ===
            STATUS.IN_PROGRESS,
      );
    },
    filteredKowledgebases(): IKnowledgebase[] {
      const { userData } = useUserStore();
      const isAllKnowledge =
        this.filters.type === KNOWLEDGE_FILTER_TYPE.ALL_KNOWLEDGE;

      if (!this.filters.keyword && isAllKnowledge) return this.knowledgebases;

      return this.knowledgebases.filter((kb) => {
        let hasType = true;
        let hasKeyword = true;

        switch (this.filters.type) {
          case KNOWLEDGE_FILTER_TYPE.MY_KNOWLEDGE:
            hasType = kb.created_by === userData?.uid;
            break;
        }

        if (this.filters.keyword) {
          const fields = [kb.id, kb.display_name];
          if (kb.account_id) {
            fields.push(kb.account_id);
          }
          kb.sources.forEach((item) => {
            if (item.source === KNOWLEDGEBASE_SOURCE.FILE_UPLOAD) {
              fields.push(`${item.filename}_${item.id}.${item.extension}`);
            }
          });
          const textToSearch = fields.join('').toLowerCase();
          hasKeyword = textToSearch.includes(
            this.filters.keyword?.toLowerCase() ?? '',
          );
        }

        return hasType && hasKeyword;
      });
    },
    kbItemsBySource: (state) => {
      return (sourceID: string) => {
        return state.kbItemIdsBySource[sourceID]
          ? state.kbItemIdsBySource[sourceID]
              .map((id) => state.kbItemEntitiesBySource[sourceID][id])
              .sort((a, b) => (a.metadata.index ?? 0) - (b.metadata.index ?? 0))
          : [];
      };
    },
  },
  actions: {
    async getKnowledgebases() {
      this.loading = true;
      const actionKey = ACTION_KEYS.GET_KNOWLEDGEBASES;
      try {
        const url = API_ROUTES.KNOWLEDGEBASES();
        const { data } = await ApiService.get<IKnowledgebase[]>(url, actionKey);
        const ids: string[] = [];
        const entities: Record<string, IKnowledgebase> = data.reduce(
          (prev, knowledgebase) => {
            ids.push(knowledgebase.id);
            prev[knowledgebase.id] = knowledgebase;
            return prev;
          },
          {} as Record<string, IKnowledgebase>,
        );
        this.$patch({
          knowledgebaseEntities: entities,
          knowledgebaseIds: ids,
          loaded: true,
        });
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = ApiService.hasInflightRequest(actionKey);
      }
    },
    async createKnowledgebase(body: FormData) {
      const { showSnackbar } = useNotifyStore();
      const actionKey = ACTION_KEYS.CREATE_KNOWLEDGEBASE;
      this.loading = true;

      try {
        const url = API_ROUTES.KNOWLEDGEBASES();
        const { data } = await ApiService.post<IKnowledgebase>(
          url,
          body,
          actionKey,
        );
        this.$patch({
          knowledgebaseIds: [...this.knowledgebaseIds, data.id],
          knowledgebaseEntities: {
            ...this.knowledgebaseEntities,
            [data.id]: data,
          },
        });
        showSnackbar(`Knowledgebase created: ${data.id}`);
        return data;
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = ApiService.hasInflightRequest(actionKey);
      }
    },
    async updateKnowledgebase(body: FormData, id: string) {
      const { showSnackbar } = useNotifyStore();
      const actionKey = `${ACTION_KEYS.UPDATE_KNOWLEDGEBASE}${id}`;
      this.loading = true;

      try {
        const url = API_ROUTES.KNOWLEDGEBASES_BY_ID(id);
        const { data } = await ApiService.put<IKnowledgebase>(
          url,
          body,
          actionKey,
        );
        this.$patch({
          knowledgebaseEntities: {
            ...this.knowledgebaseEntities,
            [data.id]: data,
          },
        });
        showSnackbar(`Knowledgebase updated: ${id}`);
        return this.knowledgebaseEntities[id];
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = ApiService.hasInflightRequest(actionKey);
      }
    },
    async deleteKnowledgebase(id: string) {
      const { showSnackbar } = useNotifyStore();
      const actionKey = ACTION_KEYS.DELETE_KNOWLEDGEBASE;
      this.loading = true;

      try {
        const url = API_ROUTES.KNOWLEDGEBASES_BY_ID(id);
        const { data } = await ApiService.delete<string>(url, actionKey);
        const { [data]: toRemove, ...knowledgebaseEntities } =
          this.knowledgebaseEntities;
        const index = this.knowledgebaseIds.findIndex(
          (id) => id === toRemove.id,
        );
        this.$patch({
          knowledgebaseIds: [
            ...this.knowledgebaseIds.slice(0, index),
            ...this.knowledgebaseIds.slice(index + 1),
          ],
          knowledgebaseEntities: {
            ...knowledgebaseEntities,
          },
        });
        showSnackbar(`Knowledgebase deleted: ${id}`);
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = ApiService.hasInflightRequest(actionKey);
      }
    },
    async downloadKnowledgebaseFile(id: string, sourceId: string) {
      const actionKey = ACTION_KEYS.GET_KNOWLEDGEBASE_FILE;
      this.loading = true;

      try {
        const entity = this.knowledgebaseEntities[id];

        const source = entity?.sources.find(
          (item) =>
            item.id === sourceId &&
            item.source === KNOWLEDGEBASE_SOURCE.FILE_UPLOAD,
        );

        if (!source || source.source !== KNOWLEDGEBASE_SOURCE.FILE_UPLOAD) {
          throw Error(`Invalid source ${entity}`);
        }

        const url = API_ROUTES.KNOWLEDGEBASES_FILE_BY_ID(id, sourceId);
        const { data } = await ApiService.get<ArrayBuffer>(
          url,
          actionKey,
          undefined,
          {
            responseType: 'arraybuffer',
          },
        );

        const { filename, extension } = source;
        const fullFilename = `${filename}.${extension}`;
        const type = HelperService.getFileTypeFromExtension(extension);
        FileService.download(data, fullFilename, type);
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = ApiService.hasInflightRequest(actionKey);
      }
    },
    async getKnowledgebaseItems(id: string, sourceId: string) {
      this.loadingItems = true;

      try {
        const actionKey = `${ACTION_KEYS.GET_KNOWLEDGEBASE_ITEMS}${id}${sourceId}`;
        const url = API_ROUTES.KNOWLEDGEBASES_ITEMS_BY_SOURCE_ID(id, sourceId);

        const { data } = await ApiService.get<IKnowledgebaseItem[]>(
          url,
          actionKey,
        );
        const ids: string[] = [];
        const entities: Record<string, IKnowledgebaseItem> = data.reduce(
          (prev, kbItem) => {
            ids.push(kbItem.id);
            prev[kbItem.id] = kbItem;
            return prev;
          },
          {} as Record<string, IKnowledgebaseItem>,
        );

        this.$patch({
          kbItemEntitiesBySource: {
            ...this.kbItemEntitiesBySource,
            [sourceId]: entities,
          },
          kbItemIdsBySource: {
            ...this.kbItemIdsBySource,
            [sourceId]: ids,
          },
        });
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loadingItems = false;
      }
    },
    async createKnowledgebaseItems(
      id: string,
      sourceId: string,
      items: IKnowledgebaseTextItemForm[],
    ) {
      const actionKey = ACTION_KEYS.CREATE_KNOWLEDGEBASE_ITEMS;
      this.loadingItems = true;

      try {
        const url = API_ROUTES.KNOWLEDGEBASES_ITEMS_BY_SOURCE_ID(id, sourceId);
        const body: IKnowledgebaseTextItem[] = items.map((item) => ({
          id: item.id || undefined,
          text: item.text,
          index: item.index,
        }));
        const { data } = await ApiService.post<IKnowledgebaseItem[]>(
          url,
          body,
          actionKey,
        );
        const ids: string[] = [];
        const entities: Record<string, IKnowledgebaseItem> = data.reduce(
          (prev, kbItem) => {
            ids.push(kbItem.id!);
            prev[kbItem.id!] = kbItem;
            return prev;
          },
          {} as Record<string, IKnowledgebaseItem>,
        );

        // Update source count
        const kbEntity = this.knowledgebaseEntities[id];
        const sourceIndex = kbEntity.sources.findIndex(
          (item) => item.id === sourceId,
        );
        const source = kbEntity.sources[sourceIndex];
        if (source) source.item_count += ids.length;

        this.$patch({
          kbItemEntitiesBySource: {
            ...this.kbItemEntitiesBySource,
            [sourceId]: {
              ...this.kbItemEntitiesBySource[sourceId],
              ...entities,
            },
          },
          kbItemIdsBySource: {
            ...this.kbItemIdsBySource,
            [sourceId]: this.kbItemIdsBySource[sourceId].concat(ids),
          },
        });
        return data;
      } catch (error) {
        ErrorService.handleRequestError(error);
        return null;
      } finally {
        this.loadingItems = ApiService.hasInflightRequest(actionKey);
      }
    },
    async updateKnowledgebaseItems(
      id: string,
      sourceId: string,
      items: IKnowledgebaseTextItemForm[],
    ) {
      const actionKey = ACTION_KEYS.UPDATE_KNOWLEDGEBASE_ITEMS;
      this.loadingItems = true;

      try {
        const url = API_ROUTES.KNOWLEDGEBASES_ITEMS_BY_SOURCE_ID(id, sourceId);
        const body: IKnowledgebaseTextItem[] = items.map((item) => ({
          id: item.id || undefined,
          text: item.text,
          index: item.index,
        }));
        const { data } = await ApiService.put<IKnowledgebaseItem[]>(
          url,
          body,
          actionKey,
        );

        const entities: Record<string, IKnowledgebaseItem> = data.reduce(
          (prev, kbItem) => {
            prev[kbItem.id!] = kbItem;
            return prev;
          },
          {} as Record<string, IKnowledgebaseItem>,
        );

        this.$patch({
          kbItemEntitiesBySource: {
            ...this.kbItemEntitiesBySource,
            [sourceId]: {
              ...this.kbItemEntitiesBySource[sourceId],
              ...entities,
            },
          },
        });
        return data;
      } catch (error) {
        ErrorService.handleRequestError(error);
        return null;
      } finally {
        this.loadingItems = ApiService.hasInflightRequest(actionKey);
      }
    },
    async deleteKnowledgebaseItems(
      id: string,
      sourceId: string,
      itemIds: string[],
    ) {
      const actionKey = ACTION_KEYS.DELETE_KNOWLEDGEBASE_ITEMS;
      this.loadingItems = true;

      try {
        const url = API_ROUTES.KNOWLEDGEBASES_ITEMS_BY_SOURCE_ID(id, sourceId);

        const { data } = await ApiService.delete<string[]>(
          url,
          actionKey,
          undefined,
          itemIds,
        );

        const idsToDelete = new Set(data);
        const newIds: string[] = [];

        const entities: Record<string, IKnowledgebaseItem> =
          this.kbItemIdsBySource[sourceId].reduce((prev, itemId) => {
            if (idsToDelete.has(itemId)) {
              return prev;
            }
            newIds.push(itemId);
            prev[itemId] = this.kbItemEntitiesBySource[sourceId][itemId];
            return prev;
          }, {} as Record<string, IKnowledgebaseItem>);

        // Update source count
        const kbEntity = this.knowledgebaseEntities[id];
        const sourceIndex = kbEntity.sources.findIndex(
          (item) => item.id === sourceId,
        );
        const source = kbEntity.sources[sourceIndex];
        if (source) source.item_count -= idsToDelete.size;

        this.$patch({
          kbItemEntitiesBySource: {
            ...this.kbItemEntitiesBySource,
            [sourceId]: entities,
          },
          kbItemIdsBySource: {
            ...this.kbItemIdsBySource,
            [sourceId]: newIds,
          },
        });
        return data;
      } catch (error) {
        ErrorService.handleRequestError(error);
        return null;
      } finally {
        this.loadingItems = ApiService.hasInflightRequest(actionKey);
      }
    },
    async calculateKnowledgebaseHealth(id: string) {
      const actionKey = ACTION_KEYS.CALCULATE_KNOWLEDGEBASE_HEALTH;
      this.loading = true;

      try {
        const url = API_ROUTES.KNOWLEDGEBASES_HEALTH_BY_ID(id);

        const { data } = await ApiService.get<IKnowledgebase>(url, actionKey);

        this.$patch({
          knowledgebaseEntities: {
            ...this.knowledgebaseEntities,
            [data.id]: data,
          },
        });
        return data;
      } catch (error) {
        ErrorService.handleRequestError(error);
        return null;
      } finally {
        this.loading = ApiService.hasInflightRequest(actionKey);
      }
    },
    async exportKnowledgebaseItems(sourceId: string, kbId?: string) {
      const { showSnackbar } = useNotifyStore();

      this.loading = true;
      showSnackbar(`Exporting knowledgebase items`);

      if (kbId) {
        await this.getKnowledgebaseItems(kbId, sourceId);
      }

      try {
        const ids = this.kbItemIdsBySource[sourceId] ?? [];
        const entities = ids.map(
          (id) => this.kbItemEntitiesBySource[sourceId][id],
        );
        const data: IKnowledgebaseItemExport[] = entities
          .map((item) => ({
            text: item.metadata.text,
            id: item.id,
            index: item.metadata.index,
            source_id: item.metadata.source_id,
            kb_id: item.metadata.kb_id,
          }))
          .sort((a, b) => a.index - b.index);
        FileService.downloadAsCsv(data, sourceId, 'knowledgebase-items');
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = false;
      }
    },
    resetKnowledgebases() {
      this.$reset();
    },
  },
});
