<template>
  <v-dialog
    scrollable
    :data-testid="TEST_IDS.common.dialog"
    :height="height"
    :max-width="maxWidth"
    :max-height="maxHeight"
    :model-value="dialog"
    :persistent="persistent"
    :fullscreen="fullscreen"
    @update:model-value="update"
    @click:outside="persistent ? $emit('close') : undefined"
  >
    <v-card
      rounded="lg"
      :class="{
        'bg-primary-lighten-6': !theme.current.value.dark,
        'bg-base-ascent-navy': theme.current.value.dark,
      }"
    >
      <v-card-title>
        <v-container fluid class="pb-0 px-2">
          <v-row
            class="border-bottom text-body-1 font-weight-medium text-medium-emphasis"
            align="center"
          >
            <v-col v-if="icon" cols="auto">
              <v-icon :color="iconColor" :icon="icon"></v-icon>
            </v-col>
            <v-col class="text-truncate">
              {{ title }}
            </v-col>
            <v-col v-if="!hideClose" cols="auto">
              <v-icon :icon="mdiClose" @click="$emit('close')"></v-icon>
            </v-col>
          </v-row>
          <slot name="header"></slot>
        </v-container>
      </v-card-title>
      <v-card-text class="overflow-x-hidden">
        <v-container
          fluid
          class="pa-1"
          :class="{
            'fill-height': fillHeight,
          }"
        >
          <slot name="main"></slot>
        </v-container>
      </v-card-text>
      <v-card-actions v-if="!hideFooter">
        <v-container fluid class="pt-0">
          <slot name="footer"></slot>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
import { TEST_IDS } from '@/constants/tests';
import { mdiClose } from '@mdi/js';
import { useTheme } from 'vuetify';

defineProps<{
  icon?: string;
  iconColor?: string;
  dialog: boolean;
  height?: number | string;
  maxWidth?: number;
  maxHeight?: number;
  persistent?: boolean;
  title: string;
  fullscreen?: boolean;
  hideClose?: boolean;
  hideFooter?: boolean;
  fillHeight?: boolean;
}>();

const emit = defineEmits(['close']);

const update = (show: boolean) => {
  if (!show) emit('close');
};

const theme = useTheme();
</script>
