import {
  CONVERSATION_ASIDE_TABS,
  HOST_MAP,
  MAX_WORD_LIMIT,
  DEFAULT_APP_ENV,
  LP_ENV,
  LP_REGION,
  CONVERSATION_NAVIGATOR_TABS,
  STORAGE_KEYS,
} from '@/constants/constants';
import { NAV_TABS } from '@/constants/routes';
import { AppEnvVar } from '@/interfaces/interfaces';
import { defineStore } from 'pinia';
import { useDisplay } from 'vuetify/lib/framework.mjs';

interface IAppState {
  loading: boolean;
  maxWordLimit: number;
  conversationAsideTab: CONVERSATION_ASIDE_TABS;
  appEnv: AppEnvVar;
  conversationNavigatorTab: CONVERSATION_NAVIGATOR_TABS;
  navTabPath: NAV_TABS[];
  showDebugVariables: boolean;
}

const appEnv = HOST_MAP[location.hostname] ?? DEFAULT_APP_ENV;

export const useAppStore = defineStore('app', {
  state: (): IAppState => ({
    loading: false,
    maxWordLimit: MAX_WORD_LIMIT,
    conversationAsideTab: CONVERSATION_ASIDE_TABS.EXPLORE,
    appEnv: appEnv,
    conversationNavigatorTab:
      (localStorage.getItem(
        STORAGE_KEYS.CONV_NAVIGATOR_TAB,
      ) as CONVERSATION_NAVIGATOR_TABS) ?? CONVERSATION_NAVIGATOR_TABS.BOTS,
    navTabPath: [],
    showDebugVariables:
      localStorage.getItem(STORAGE_KEYS.SHOW_DEBUG_VARIABLES) === 'true' ??
      false,
  }),
  actions: {
    setConversationNavigatorTab(tab: CONVERSATION_NAVIGATOR_TABS) {
      if (!tab || !CONVERSATION_NAVIGATOR_TABS[tab]) return;
      this.conversationNavigatorTab = tab;
      localStorage.setItem(STORAGE_KEYS.CONV_NAVIGATOR_TAB, tab);
    },
    setShowDebugVariables(show: boolean) {
      this.showDebugVariables = show;
      localStorage.setItem(STORAGE_KEYS.SHOW_DEBUG_VARIABLES, show.toString());
    },
    setNavTabPath(path: NAV_TABS[]) {
      this.navTabPath = path;
    },
    resetApp() {
      this.$reset();
    },
  },
  getters: {
    navHeight(state) {
      return 60 + 45 * (state.navTabPath.length || 1);
    },
    contentHeight(): number {
      const { height } = useDisplay();
      return height.value - this.navHeight;
    },
    tableHeaderHeight(): number {
      const { mdAndDown } = useDisplay();
      const tableActionsHeight = mdAndDown.value ? 300 : 250;
      return this.navHeight + tableActionsHeight;
    },
    tableHeight(): number {
      const { height } = useDisplay();
      return height.value - this.tableHeaderHeight;
    },
    tableItems(): number {
      return Math.floor(this.tableHeight / 60);
    },
    debugEnabled(state) {
      return state.conversationAsideTab === CONVERSATION_ASIDE_TABS.DEBUG;
    },
    region(state) {
      switch (state.appEnv.env) {
        case LP_ENV.GCP_PROD_AU:
          return LP_REGION.AU;
        case LP_ENV.GCP_PROD_EU:
          return LP_REGION.EU;
        default:
          return LP_REGION.US;
      }
    },
  },
});
