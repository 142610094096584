import { ACTION_KEYS, API_ROUTES, UNKNOWN_SOURCE } from '@/constants/constants';
import type { ICategory, IColor } from '@/interfaces/interfaces';
import ApiService from '@/services/ApiService';
import { defineStore } from 'pinia';
import ErrorService from '@/services/ErrorService';
import { CreateCategoryDTO, UpdateCategoryDTO } from '@/interfaces/dtos';
import { useNotifyStore } from './notify';
import HelperService from '@/services/HelperService';

export interface ICategoriesState {
  loading: boolean;
  loaded: boolean;
  selectedCategoryId: string | null;
  categoryEntities: Record<string, ICategory>;
  categoryIds: string[];
  colors: Map<string, IColor>;
}

export const useCategoriesStore = defineStore('categories', {
  state: (): ICategoriesState => ({
    loading: false,
    loaded: false,
    selectedCategoryId: null,
    categoryEntities: {},
    categoryIds: [],
    colors: new Map(),
  }),
  getters: {
    categories: (state) =>
      state.categoryIds
        .map((id) => state.categoryEntities[id])
        .sort((a, b) => (b.updated_at ?? 0) - (a.updated_at ?? 0)),
    selectedCategory: (state) =>
      state.selectedCategoryId
        ? state.categoryEntities[state.selectedCategoryId]
        : null,
  },
  actions: {
    async getCategories() {
      this.loading = true;
      const actionKey = ACTION_KEYS.GET_CATEGORIES;
      try {
        const url = API_ROUTES.CATEGORIES();
        const { data } = await ApiService.get<ICategory[]>(url, actionKey);
        const ids: string[] = [];
        const entities: Record<string, ICategory> = data.reduce(
          (prev, category) => {
            ids.push(category.id);
            prev[category.id] = category;
            return prev;
          },
          {} as Record<string, ICategory>,
        );
        // add fallback category
        entities[UNKNOWN_SOURCE.CATEGORY_ID] = {
          display_description: UNKNOWN_SOURCE.CATEGORY_DESC,
          display_name: UNKNOWN_SOURCE.CATEGORY_NAME,
          id: UNKNOWN_SOURCE.CATEGORY_ID,
          created_by: UNKNOWN_SOURCE.CATEGORY_ID,
          updated_by: UNKNOWN_SOURCE.CATEGORY_ID,
          created_at: Date.now(),
          updated_at: Date.now(),
        };
        this.$patch({
          categoryEntities: entities,
          categoryIds: ids,
          colors: HelperService.getColors(data.map((c) => c.id)),
          loaded: true,
        });
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = ApiService.hasInflightRequest(actionKey);
      }
    },
    async createCategory(body: CreateCategoryDTO) {
      const { showSnackbar } = useNotifyStore();
      const actionKey = ACTION_KEYS.CREATE_CATEGORY;
      this.loading = true;

      try {
        const url = API_ROUTES.CATEGORIES();
        const { data } = await ApiService.post<ICategory>(url, body, actionKey);
        this.$patch({
          categoryIds: [...this.categoryIds, data.id],
          categoryEntities: {
            ...this.categoryEntities,
            [data.id]: data,
          },
        });
        showSnackbar(`Category created: ${data.id}`);
        return data;
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = ApiService.hasInflightRequest(actionKey);
      }
    },
    async updateCategory(body: UpdateCategoryDTO, id: string) {
      const { showSnackbar } = useNotifyStore();
      const actionKey = `${ACTION_KEYS.UPDATE_CATEGORY}${id}`;
      this.loading = true;

      try {
        const url = API_ROUTES.CATEGORIES_BY_ID(id);
        const { data } = await ApiService.put<ICategory>(url, body, actionKey);
        this.$patch({
          categoryEntities: {
            ...this.categoryEntities,
            [data.id]: data,
          },
        });
        showSnackbar(`Category updated: ${id}`);
        return this.categoryEntities[id];
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = ApiService.hasInflightRequest(actionKey);
      }
    },
    async deleteCategory(id: string) {
      const { showSnackbar } = useNotifyStore();
      const actionKey = ACTION_KEYS.DELETE_CATEGORY;
      this.loading = true;

      try {
        const url = API_ROUTES.CATEGORIES_BY_ID(id);
        const { data } = await ApiService.delete<string>(url, actionKey);
        const { [data]: toRemove, ...categoryEntities } = this.categoryEntities;
        const index = this.categoryIds.findIndex((id) => id === toRemove.id);
        this.$patch({
          categoryIds: [
            ...this.categoryIds.slice(0, index),
            ...this.categoryIds.slice(index + 1),
          ],
          categoryEntities: {
            ...categoryEntities,
          },
        });
        showSnackbar(`Category deleted: ${id}`);
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = ApiService.hasInflightRequest(actionKey);
      }
    },
    resetCategories() {
      this.$reset();
    },
  },
});
