import { API_ROUTES, ACTION_KEYS, STATUS } from '@/constants/constants';
import {
  IBatchSummary,
  IBatchSummaryResponse,
  IConversationSummary,
  ISummaryBatchFilters,
} from '@/interfaces/interfaces';
import ApiService from '@/services/ApiService';
import { defineStore } from 'pinia';
import {
  CreateConversationSummaryDTO,
  CreateBatchSummaryDTO,
} from '@/interfaces/dtos';
import ErrorService from '@/services/ErrorService';
import { useNotifyStore } from './notify';

export interface ISummaryState {
  loading: boolean;
  loaded: boolean;
  conversationSummary: IConversationSummary;
  batchSummaries: IBatchSummary[];
  filters: ISummaryBatchFilters;
}

export const useSummaryStore = defineStore('summary', {
  state: (): ISummaryState => ({
    loading: false,
    loaded: false,
    conversationSummary: {
      result: '',
      created_at: 0,
    },
    batchSummaries: [],
    filters: {
      offset: 0,
      limit: 10,
    },
  }),
  getters: {
    activeBatchSummaries(state) {
      return state.batchSummaries.filter(
        (summary) => summary.status === STATUS.IN_PROGRESS,
      );
    },
  },
  actions: {
    resetSummary() {
      this.$reset();
    },
    updateFilters(filters: Partial<ISummaryBatchFilters>) {
      this.$patch({ filters });
    },
    async createConversationSummary(convId: string, structured: boolean) {
      const key = ACTION_KEYS.CREATE_CONVERSATION_SUMMARY;
      try {
        this.loading = true;

        const url = API_ROUTES.SUMMARY();
        const body: CreateConversationSummaryDTO = {
          conv_id: convId,
          structured_summary: structured,
        };
        const { data } = await ApiService.post<IConversationSummary>(
          url,
          body,
          key,
        );
        this.$patch({
          conversationSummary: {
            result: data.result,
            created_at: data.created_at,
          },
        });
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = ApiService.hasInflightRequest(key);
      }
    },
    async createBatchSummary(query: CreateBatchSummaryDTO) {
      const key = ACTION_KEYS.CREATE_BATCH_SUMMARY;
      try {
        this.loading = true;

        const url = API_ROUTES.SUMMARY_BATCH();
        const { data } = await ApiService.post<IBatchSummaryResponse>(
          url,
          query,
          key,
        );
        this.$patch({
          batchSummaries: [data.result, ...this.batchSummaries],
        });
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = ApiService.hasInflightRequest(key);
      }
    },
    async getBatchSummaries() {
      const key = ACTION_KEYS.GET_BATCH_SUMMARIES;
      try {
        this.loading = true;

        const url = API_ROUTES.SUMMARY_BATCH();
        const { data } = await ApiService.get<IBatchSummary[]>(
          url,
          key,
          this.filters,
        );
        this.$patch({ batchSummaries: data, loaded: true });
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = ApiService.hasInflightRequest(key);
      }
    },
    async getBatchSummary(id: string) {
      const key = ACTION_KEYS.GET_BATCH_SUMMARY;
      try {
        this.loading = true;

        const url = API_ROUTES.SUMMARY_BATCH_BY_ID(id);
        const { data } = await ApiService.get<IBatchSummary>(
          url,
          key,
          this.filters,
        );
        const idx = this.batchSummaries.findIndex(
          (item) => item.id === data.id,
        );
        this.batchSummaries[idx] = data;
        return data;
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = ApiService.hasInflightRequest(key);
      }
    },
    async deleteBatchSummary(id: string) {
      const { showSnackbar } = useNotifyStore();
      const key = ACTION_KEYS.DELETE_BATCH_SUMMARIES;
      try {
        this.loading = true;

        const url = API_ROUTES.SUMMARY_BATCH_BY_ID(id);
        const { data } = await ApiService.delete<string>(url, key);
        const index = this.batchSummaries.findIndex((item) => item.id === data);
        this.$patch({
          batchSummaries: [
            ...this.batchSummaries.slice(0, index),
            ...this.batchSummaries.slice(index + 1),
          ],
        });
        showSnackbar(`Summary deleted: ${id}`);
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = ApiService.hasInflightRequest(key);
      }
    },
  },
});
