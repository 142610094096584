<template>
  <v-main>
    <v-container class="fill-height">
      <v-row justify="center" align="center" class="fill-height">
        <v-col cols="auto">
          <v-progress-circular size="100" indeterminate color="primary">
            Loading...
          </v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script lang="ts" setup></script>
