<template>
  <BaseDialog
    :dialog="dialog"
    :max-width="notify.maxWidth ?? 550"
    :title="notify.title"
    :icon="notify.icon"
    :icon-color="notify.iconColor"
    @close="!notify.hideClose ? (dialog = false) : undefined"
    :persistent="notify.persistent"
    :hide-close="notify.hideClose"
  >
    <template v-slot:main>
      <div v-html="notify.body"></div>
    </template>
    <template v-slot:footer>
      <v-checkbox
        v-if="notify.primaryAgree"
        v-model="agreed"
        :label="notify.primaryAgreeLabel"
        hide-details
      ></v-checkbox>
      <v-row justify="end">
        <v-col v-if="notify.cancelLabel" cols="auto" class="pr-3">
          <v-btn variant="text" color="on-surface" @click="cancelClick">
            {{ notify.cancelLabel }}
          </v-btn>
        </v-col>
        <v-col v-if="notify.secondaryLabel && notify.onSecondaryFn" cols="auto">
          <v-btn
            :loading="loadingSecondary && !notify.closeOnSecondary"
            :disabled="loadingPrimary"
            variant="outlined"
            @click="secondaryClick"
          >
            {{ notify.secondaryLabel }}
          </v-btn>
        </v-col>
        <v-col v-if="notify.primaryLabel && notify.onPrimaryFn" cols="auto">
          <v-btn
            :loading="loadingPrimary && !notify.closeOnPrimary"
            :disabled="loadingSecondary || (notify.primaryAgree && !agreed)"
            color="primary"
            @click="primaryClick"
          >
            {{ notify.primaryLabel }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </BaseDialog>
</template>

<script lang="ts" setup>
import { ref, reactive } from 'vue';
import { useNotifyStore } from '@/store/notify';
import BaseDialog from '@/components/BaseDialog.vue';
import { INotifyDialog } from '@/interfaces/interfaces';

const notify = reactive<INotifyDialog>({ title: '', body: '' });

const loadingPrimary = ref(false);
const loadingSecondary = ref(false);
const dialog = ref(false);
const agreed = ref(false);

const store = useNotifyStore();

const cancelClick = async () => {
  if (notify.onCancelFn) {
    notify.onCancelFn();
  }
  dialog.value = false;
};

const secondaryClick = async () => {
  if (notify.closeOnSecondary) {
    dialog.value = false;
  }
  if (notify.onSecondaryFn) {
    loadingSecondary.value = true;
    await notify.onSecondaryFn();
    loadingSecondary.value = false;
  }
  if (notify.closeAfterSecondary) {
    dialog.value = false;
  }
};

const primaryClick = async () => {
  if (notify.closeOnPrimary) {
    dialog.value = false;
  }
  if (notify.onPrimaryFn) {
    loadingPrimary.value = true;
    await notify.onPrimaryFn();
    loadingPrimary.value = false;
  }
  if (notify.closeAfterPrimary) {
    dialog.value = false;
  }
};

store.$onAction(({ name, after }) => {
  if (name === 'showConfirmDialog') {
    after((data) => {
      notify.icon = data.icon;
      notify.maxWidth = data.maxWidth;
      notify.iconColor = data.iconColor;
      notify.title = data.title;
      notify.body = data.body;
      notify.persistent = data.persistent;
      notify.hideClose = data.hideClose;
      notify.primaryAgree = data.primaryAgree;
      notify.primaryAgreeLabel = data.primaryAgreeLabel;

      notify.cancelLabel = data.cancelLabel;
      notify.primaryLabel = data.primaryLabel;
      notify.secondaryLabel = data.secondaryLabel;
      notify.onCancelFn = data.onCancelFn;
      notify.onPrimaryFn = data.onPrimaryFn;
      notify.onSecondaryFn = data.onSecondaryFn;

      notify.closeOnPrimary = data.closeOnPrimary ?? true;
      notify.closeOnSecondary = data.closeOnSecondary ?? true;
      notify.closeAfterPrimary =
        data.closeAfterPrimary ?? !notify.closeOnPrimary;
      notify.closeAfterSecondary =
        data.closeAfterSecondary ?? !notify.closeOnSecondary;

      dialog.value = true;
    });
  }
});
</script>

<style scope></style>
