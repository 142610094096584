import { API_ROUTES, ACTION_KEYS } from '@/constants/constants';
import { IConversationQuery } from '@/interfaces/interfaces';
import ApiService from '@/services/ApiService';
import { defineStore } from 'pinia';
import { CreateConversationQueryDTO } from '@/interfaces/dtos';
import ErrorService from '@/services/ErrorService';
import { useConversationStore } from './conversation';

export interface IQueryState {
  loading: boolean;
  conversationQuery: IConversationQuery;
}

export const useQueryStore = defineStore('query', {
  state: (): IQueryState => ({
    loading: false,
    conversationQuery: {
      result: '',
      created_at: 0,
    },
  }),
  actions: {
    resetQuery() {
      this.$reset();
    },
    async createConversationQuery(query: string) {
      const { conversation } = useConversationStore();
      const key = ACTION_KEYS.CREATE_CONVERSATION_QUERY;
      try {
        if (!conversation) {
          throw Error('Conversation is missing');
        }
        this.loading = true;

        const url = API_ROUTES.QUERY();
        const body: CreateConversationQueryDTO = {
          query,
          conv_id: conversation.id,
        };
        const { data } = await ApiService.post<IConversationQuery>(
          url,
          body,
          key,
        );
        this.$patch({
          conversationQuery: {
            result: data.result,
            created_at: data.created_at,
          },
        });
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = ApiService.hasInflightRequest(key);
      }
    },
  },
});
