import 'vuetify/styles';

import { createVuetify, ThemeDefinition } from 'vuetify';
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg';
import { md2 } from 'vuetify/blueprints';
import { DEFAULT_THEME } from '@/constants/constants';

const lightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    primary: '#3863E5',
    // "primary-lighten-1": "#6687EB",
    // 'primary-lighten-2': '#93A6F0',
    // 'primary-lighten-3': '#AFB9F3',
    // 'primary-lighten-4': '#D3D7F8',
    'primary-lighten-5': '#E7E9FB',
    'primary-lighten-6': '#F2F3FD',
    'primary-lighten-7': '#F8FBFE',
    secondary: '#EA6B22',
    highlight: '#AFB9F3',
  },
};

const darkTheme: ThemeDefinition = {
  dark: true,
  colors: {
    'base-ascent-navy': '#090C43',
    primary: '#6385EB',
    surface: '#12154A',
    'primary-darken-5': '#20265F',
    'primary-darken-6': '#1D2258',
    'primary-darken-7': '#070932',
    secondary: '#EA6B22',
    highlight: '#AFB9F3',
  },
};

export default createVuetify({
  blueprint: md2,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  theme: {
    variations: {
      colors: ['primary', 'grey'],
      lighten: 3,
      darken: 3,
    },
    defaultTheme: DEFAULT_THEME,
    themes: {
      dark: darkTheme,
      light: lightTheme,
    },
  },
});
