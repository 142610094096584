import Ajv from 'ajv';
import AjvErrors from 'ajv-errors';

export const ajv = new Ajv({ allErrors: true });
AjvErrors(ajv);

export const jsonUploadSchema = {
  type: 'object',
  properties: {
    report: {
      type: 'array',
      items: { type: 'string' },
    },
  },
  required: ['report'],
  additionalProperties: false,
  errorMessage:
    'must be an object with a single array property "report" which contains string values only',
};

// Schema for CSV, after the csv has been converted to a json for validation purposes
export const csvUploadSchema = {
  type: 'array',
  items: {
    type: 'object',
    properties: {
      text: { type: 'string' },
    },
    required: ['text'],
    errorMessage:
      'csv file must contain at least a "text" header column representing the text content of the knowledgebase articles to import',
  },
};

export const jsonConversationUploadSchema = {
  type: 'array',
  items: {
    type: 'object',
    properties: {
      mcs_standard: {
        type: 'number',
        enum: [100, 0, -100],
      },
      skill_ids: {
        type: 'array',
        items: {
          type: 'string',
        },
      },
      custom_name: { type: 'string' },
      custom_label: { type: 'string' },
      messages: {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            speaker: { type: 'string' },
            text: { type: 'string' },
            time: { type: 'integer' },
          },
          required: ['speaker', 'text', 'time'],
        },
      },
    },
    required: ['custom_name', 'messages'],
    additionalProperties: true,
    errorMessage:
      'Each Conversation JSON must contain a "custom_name" and a messages list of items each with a "speaker", "text", and "time"',
  },
};

export const jsonFlowImportSchema = {
  type: 'object',
  properties: {
    nodes: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          id: { type: 'string' },
          type: { type: 'string' },
          data: {
            type: 'object',
            additionalProperties: {
              type: 'object',
              properties: {
                show: { type: 'boolean' },
                value: {
                  type: [
                    'number',
                    'null',
                    'object',
                    'array',
                    'string',
                    'boolean',
                  ],
                },
              },
              required: [],
            },
          },
          position: {
            type: 'object',
            properties: {
              x: { type: 'number' },
              y: { type: 'number' },
            },
            required: [],
          },
          base_type: { type: 'string' },
        },
        required: [],
        title: 'Node',
      },
    },
    edges: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          id: { type: 'string' },
          source: { type: 'string' },
          target: { type: 'string' },
          sourceHandle: { type: 'string' },
          targetHandle: { type: 'string' },
        },
        required: [],
        title: 'Edge',
      },
    },
    display_name: { type: 'string' },
    account_id: { type: ['null', 'string'] },
    public: { type: 'boolean' },
    template: { type: 'boolean' },
    template_group: { type: ['null', 'string'] },
    description: { type: ['null', 'string'] },
    created_at: { type: 'integer' },
    created_by: { type: 'string' },
    updated_at: { type: 'integer' },
    updated_by: { type: 'string' },
  },
  required: [],
  title: 'Flow',
};

export const questionsUploadSchema = {
  type: 'array',
  minItems: 1,
  items: { type: 'array', items: { type: 'string', minItems: 1 } },
  required: [],
  additionalProperties: false,
  errorMessage: 'must be an list of strings',
};

export const jsonQuestionsUploadSchema = {
  type: 'array',
  minItems: 1,
  items: {
    type: 'object',
    properties: {
      question: { type: 'string' },
      answer: { type: 'string' },
      parent_id: { type: 'string' },
    },
    required: ['question'],
  },
  additionalProperties: false,
  errorMessage:
    'must be an array of objects with a "question" property and optional "answer" property',
};

export const csvKnowledgebaseItemsUploadSchema = {
  type: 'array',
  minItems: 1,
  items: {
    type: 'object',
    properties: {
      text: { type: 'string' },
      id: { type: 'string', nullable: true },
      index: { type: 'string', nullable: true },
      source_id: { type: 'string', nullable: true },
      kb_id: { type: 'string', nullable: true },
    },
    required: ['text'],
    additionalProperties: false,
  },
  errorMessage: 'must be an array of objects with a required "text" property',
};

export const csvTranscriptAnalysisQuestionsUploadSchema = {
  type: 'array',
  minItems: 1,
  items: {
    type: 'object',
    properties: {
      label: { type: 'string' },
      question: { type: 'string' },
    },
    required: ['label', 'question'],
    additionalProperties: false,
  },
  errorMessage: 'CSV must contain a "label" and "question" header column',
};

export const transcriptAnalyzerConversationsUploadSchema = {
  type: 'array',
  items: {
    type: 'object',
    properties: {
      id: { type: 'string' },
      transcript: { type: 'string' },
    },
    required: ['id', 'transcript'],
    errorMessage:
      'csv file must contain a "id" header column representing the ID of the conversation, and a "transcript" header column containing the conversation text',
  },
};
