import { defineStore } from 'pinia';
import { INotifyDialog } from '@/interfaces/interfaces';

export const useNotifyStore = defineStore('notify', {
  actions: {
    showSnackbar(message: string, caption?: string, timeout = 4000) {
      return { message, caption, timeout };
    },
    showConfirmDialog(data: INotifyDialog) {
      return data;
    },
  },
});
