import { parse, unparse } from 'papaparse';
import type { ParseResult } from 'papaparse';
import { saveAs } from 'file-saver';
// import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ajv } from '@/constants/schema';

export class FileService {
  private get date() {
    return new Date().toISOString().substring(0, 19);
  }

  private async convertToPng(el: HTMLElement) {
    const canvas = await html2canvas(el);
    return canvas.toDataURL('image/png');
  }

  async downloadAsPng(el: HTMLElement, id: string) {
    const imgData = await this.convertToPng(el);
    saveAs(imgData, `conversation-${id}-${this.date}.png`);
  }

  // async downloadAsPdf(el: HTMLElement, id: string) {
  //   const doc = new jsPDF();
  //   doc.addImage({
  //     imageData: await this.convertToPng(el),
  //     x: 0,
  //     y: 0,
  //     width: doc.internal.pageSize.getWidth(),
  //     height: doc.internal.pageSize.getHeight(),
  //   });
  //   doc.save(`conversation-${id}-${this.date}.csv`);
  // }

  download(data: ArrayBuffer, filename: string, type: string) {
    const blob = new Blob([data], { type });
    saveAs(blob, filename);
  }

  downloadAsJson(
    data: any,
    id: string,
    prefix = 'conversations',
    date: boolean = true,
  ) {
    const blob = new Blob([JSON.stringify(data)], {
      type: 'application/json;charset=utf-8',
    });

    saveAs(blob, `${prefix}-${id}${date ? '-' + this.date : ''}.json`);
  }

  downloadAsCsv(rows: any[], id: string, prefix = 'conversations') {
    const data = unparse(rows, { escapeFormulae: true });
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, `${prefix}-${id}-${this.date}.csv`);
  }

  parseJson<T>(data: File, schema: any): Promise<T> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async () => {
        if (!reader.result) {
          return reject(null);
        }

        let json: any;
        try {
          json = JSON.parse(reader.result as string);
        } catch (err) {
          return reject('Could not read file as valid JSON');
        }

        const validate = ajv.compile(schema);
        const valid = validate(json);
        if (!valid) {
          return reject(
            validate.errors?.find((error) => error.message)?.message,
          );
        }

        return resolve(json);
      };
      reader.readAsText(data);
    });
  }

  parseCsv<T>(
    data: File,
    schema: any,
    header: boolean = true,
  ): Promise<ParseResult<T>> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async () => {
        if (!reader.result) {
          return reject(null);
        }

        let csvToJson: ParseResult<T>;
        try {
          csvToJson = parse<T>(reader.result as string, {
            header,
            skipEmptyLines: true,
          });
        } catch (err) {
          return reject('Could not read file as valid CSV');
        }

        const validate = ajv.compile(schema);
        const valid = validate(csvToJson.data);
        if (!valid) {
          console.error(validate.errors);
          return reject(
            validate.errors?.find((error) => error.message)?.message,
          );
        }

        return resolve(csvToJson);
      };
      reader.readAsText(data);
    });
  }
}

export default new FileService();
