export const NEW_SIMULATION_ID = 'new-simulation';

export enum SIMULATION_TYPE {
  QUESTION_ANSWER = 'QUESTION_ANSWER',
  CONVERSATION = 'CONVERSATION',
}

export enum SIMULATION_JOB_TYPE {
  MODEL = 'MODEL',
  FLOW = 'FLOW',
}

export enum SIMULATIONS_FILTER_KEY {
  ACCOUNT_ID = 'account_id',
  OWNER = 'owner',
  SIMULATION_TYPE = 'simulation_type',
}

export enum SIMULATION_QUESTION_SOURCE {
  MANUAL = 'MANUAL',
  SIMULATION = 'SIMULATION',
  CONVERSATION_CLOUD = 'CONVERSATION_CLOUD',
  KNOWLEDGEBASE = 'KNOWLEDGEBASE',
}
