import App from './App.vue';
import { createApp } from 'vue';
import { registerPlugins } from '@/plugins';

import 'vanilla-jsoneditor/themes/jse-theme-dark.css';

const app = createApp(App);

registerPlugins(app);

app.mount('#app');
