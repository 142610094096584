import { useNotifyStore } from '@/store/notify';
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
  signInWithEmailAndPassword,
  sendEmailVerification,
} from 'firebase/auth';
import { useCurrentUser } from 'vuefire';
import ApiService from './ApiService';
import { useUserStore } from '@/store/user';
import { ACTION_KEYS, API_ROUTES } from '@/constants/constants';

class AuthService {
  async getToken() {
    const user = useCurrentUser();
    return user.value?.getIdToken();
  }

  async signInWithGoogle() {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(getAuth(), provider);
  }

  async signInWithEmail(email: string, password: string) {
    const { showSnackbar } = useNotifyStore();
    const url = import.meta.env.DEV
      ? `http://${location.hostname}:3000`
      : `https://${location.hostname}`;
    const actionCodeSettings = {
      url,
      handleCodeInApp: true,
    };
    const result = await signInWithEmailAndPassword(getAuth(), email, password);
    if (!result.user.emailVerified) {
      sendEmailVerification(result.user, actionCodeSettings);
      showSnackbar(
        'Your email requires verification',
        'Please check your inbox or junk items and follow the verification link',
        -1,
      );
    }
    return result;
  }

  signOutFirebase() {
    return signOut(getAuth());
  }

  signOutCC() {
    const { accountId } = useUserStore();
    if (!accountId) return;

    const URL = API_ROUTES.SSO_LOGOUT(accountId);
    return ApiService.get(URL, ACTION_KEYS.SSO_LOGOUT);
  }
}

export default new AuthService();
