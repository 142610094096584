import { ACTION_KEYS, API_ROUTES } from '@/constants/constants';
import {
  IResolutionEval,
  IResolutionEvalCreate,
} from '@/interfaces/evaluators.interface';
import ApiService from '@/services/ApiService';
import ErrorService from '@/services/ErrorService';
import { defineStore } from 'pinia';

export interface IEvaluationState {
  loading: boolean;
  resolution: IResolutionEval | null;
  updated_at: number | null;
}

export const useEvaluationStore = defineStore('evaluation', {
  state: (): IEvaluationState => ({
    loading: false,
    resolution: null,
    updated_at: null,
  }),
  actions: {
    resetResolution() {
      this.$reset();
    },
    async getResolutionEvaluation(conversation_id: string) {
      const key = ACTION_KEYS.GET_RESOLUTION_EVALUATION;
      try {
        this.loading = true;
        const url = API_ROUTES.RESOLUTION_EVAL();
        const body: IResolutionEvalCreate = { conv_id: conversation_id };

        const { data } = await ApiService.post<IResolutionEval>(url, body, key);

        this.$patch({ resolution: data, updated_at: Date.now() });
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = ApiService.hasInflightRequest(key);
      }
    },
  },
});
