import {
  mdiAccountMultipleOutline,
  mdiBookOpenOutline,
  mdiBookOutline,
  mdiBookSearchOutline,
  mdiCogOutline,
  mdiFaceAgent,
  mdiGraphOutline,
  mdiPowerPlugOutline,
  mdiRobotOutline,
  mdiSeesaw,
  mdiShapeOutline,
  mdiSitemapOutline,
  mdiTextBoxSearchOutline,
} from '@mdi/js';
import {
  ROLES,
  ROLES_AGENT_WIDGET,
  ROLES_BOTS,
  ROLES_BOTS_INTERNAL,
  ROLES_CATEGORIES,
  ROLES_CONNECTORS,
  ROLES_CONNECTORS_INTERNAL,
  ROLES_CONVERSATION_HISTORY,
  ROLES_CONVERSATION_PLAYGROUND,
  ROLES_FLOWS,
  ROLES_KNOWLEDGE,
  ROLES_KNOWLEDGE_INTERNAL,
  ROLES_MODEL_MANAGERS,
  ROLES_PRIVILEGED,
  ROLES_SIMULATIONS,
  ROLES_SUMMARIES,
  ROLES_SUMMARIES_INTERNAL,
  ROLES_TRANSCRIPT_ANALYSIS,
  ROLES_USERS,
} from './roles';
import { TEST_IDS } from './tests';
import { INavTab } from '@/interfaces/interfaces';
import { LD_FLAGS, PERMISSIONS } from './constants';
import HelperService from '@/services/HelperService';

export enum ROUTE_NAMES {
  LOGIN = 'Login',
  CONVERSATION_PLAYGROUND = 'ConversationPlayground',
  CONVERSATION_PLAYGROUND_SELECT = 'ConversationPlaygroundSelect',
  SETTINGS = 'Settings',
  BOTS = 'Bots',
  KNOWLEDGEBASES = 'Knowledgebases',
  FLOWS = 'Flows',
  FLOWS_SELECT = 'FlowsSelect',
  CONNECTORS = 'Connectors',
  CATEGORIES = 'Categories',
  USERS = 'Users',
  LIBRARY = 'Library',
  CONVERSATION_HISTORY = 'ConversationHistory',
  SUMMARIES = 'Summaries',
  TOOLBOX = 'Toolbox',
  SIMULATIONS = 'Simulations',
  SIMULATIONS_SELECT = 'SimulationsSelect',
  TRANSCRIPT_ANALYSIS = 'TranscriptAnalysis',
  TRANSCRIPT_ANALYSIS_SELECT = 'TranscriptAnalysisSelect',
  AGENT_WIDGET = 'AgentWidget',
  SANDBOX = 'Sandbox',
  SANDBOX_FLOW = 'SandboxFlow',
}

export const TITLE_SUFFIX = 'aiStudio';

export const ROUTE_TITLES: { [p in ROUTE_NAMES]: string } = {
  [ROUTE_NAMES.LOGIN]: `Login - ${TITLE_SUFFIX}`,
  [ROUTE_NAMES.CONVERSATION_PLAYGROUND]: `Playground - ${TITLE_SUFFIX}`,
  [ROUTE_NAMES.CONVERSATION_PLAYGROUND_SELECT]: `Playground - ${TITLE_SUFFIX}`,
  [ROUTE_NAMES.SETTINGS]: `Administration - ${TITLE_SUFFIX}`,
  [ROUTE_NAMES.BOTS]: `Bots - ${TITLE_SUFFIX}`,
  [ROUTE_NAMES.KNOWLEDGEBASES]: `Knowledgebase - ${TITLE_SUFFIX}`,
  [ROUTE_NAMES.FLOWS]: `Flows - ${TITLE_SUFFIX}`,
  [ROUTE_NAMES.FLOWS_SELECT]: `Flow - ${TITLE_SUFFIX}`,
  [ROUTE_NAMES.CONNECTORS]: `Connectors - ${TITLE_SUFFIX}`,
  [ROUTE_NAMES.CATEGORIES]: `Categories - ${TITLE_SUFFIX}`,
  [ROUTE_NAMES.USERS]: `Users - ${TITLE_SUFFIX}`,
  [ROUTE_NAMES.LIBRARY]: `Library - ${TITLE_SUFFIX}`,
  [ROUTE_NAMES.CONVERSATION_HISTORY]: `Conversation History - ${TITLE_SUFFIX}`,
  [ROUTE_NAMES.SUMMARIES]: `Summaries Library - ${TITLE_SUFFIX}`,
  [ROUTE_NAMES.TOOLBOX]: `Toolbox - ${TITLE_SUFFIX}`,
  [ROUTE_NAMES.SIMULATIONS]: `Simulations - ${TITLE_SUFFIX}`,
  [ROUTE_NAMES.SIMULATIONS_SELECT]: `Simulation - ${TITLE_SUFFIX}`,
  [ROUTE_NAMES.TRANSCRIPT_ANALYSIS]: `Transcript Analyzer - ${TITLE_SUFFIX}`,
  [ROUTE_NAMES.TRANSCRIPT_ANALYSIS_SELECT]: `Transcript Analyzer - ${TITLE_SUFFIX}`,
  [ROUTE_NAMES.AGENT_WIDGET]: `Agent Widget - ${TITLE_SUFFIX}`,
  [ROUTE_NAMES.SANDBOX]: `Sandbox - ${TITLE_SUFFIX}`,
  [ROUTE_NAMES.SANDBOX_FLOW]: `Sandbox Flow - ${TITLE_SUFFIX}`,
};

export enum NAV_TABS {
  CONVERSATION_HISTORY = 'CONVERSATION_HISTORY',
  CONVERSATION_PLAYGROUND = 'CONVERSATION_PLAYGROUND',
  SUMMARIES = 'SUMMARIES',
  BOTS = 'BOTS',
  KNOWLEDGEBASES = 'KNOWLEDGEBASES',
  FLOWS = 'FLOWS',
  CONNECTORS = 'CONNECTORS',
  CATEGORIES = 'CATEGORIES',
  USERS = 'USERS',
  SIMULATIONS = 'SIMULATIONS',
  TRANSCRIPT_ANALYSIS = 'TRANSCRIPT_ANALYSIS',
  EXTRAS = 'EXTRAS',
  ADMIN = 'ADMIN',
  SANDBOX = 'SANDBOX',
  AGENT_WIDGET = 'AGENT_WIDGET',
}

export type NavTabMap<T> = { [keyof in NAV_TABS]: T };

export const CAN_ACCESS_TAB: NavTabMap<() => boolean> = {
  [NAV_TABS.FLOWS]: () => {
    return (
      HelperService.isAuthorized(ROLES_FLOWS) &&
      HelperService.isEnabled(LD_FLAGS.FLOWS_ENABLED)
    );
  },
  [NAV_TABS.CONVERSATION_HISTORY]: () => {
    return HelperService.isAuthorized(ROLES_CONVERSATION_HISTORY);
  },
  [NAV_TABS.CONVERSATION_PLAYGROUND]: () => {
    return (
      HelperService.isAuthorized(ROLES_CONVERSATION_PLAYGROUND) &&
      HelperService.isEnabled(LD_FLAGS.CONVERSATION_PLAYGROUND)
    );
  },
  [NAV_TABS.SIMULATIONS]: () => {
    return (
      HelperService.isAuthorized(ROLES_SIMULATIONS) &&
      HelperService.isEnabled(LD_FLAGS.TOOLBOX_SIMULATIONS_ENABLED)
    );
  },
  [NAV_TABS.TRANSCRIPT_ANALYSIS]: () => {
    return (
      HelperService.isAuthorized(
        ROLES_TRANSCRIPT_ANALYSIS,
        PERMISSIONS.TRANSCRIPT_ANALYZER,
      ) && HelperService.isEnabled(LD_FLAGS.TOOLBOX_TRANSCRIPT_ANALYSIS_ENABLED)
    );
  },
  [NAV_TABS.EXTRAS]: () => {
    return HelperService.isAuthorized(
      ROLES_PRIVILEGED.concat(ROLES_MODEL_MANAGERS),
    );
  },
  [NAV_TABS.BOTS]: () => {
    if (!HelperService.isEnabled(LD_FLAGS.BOTS_ENABLED)) {
      return false;
    }
    if (HelperService.isEnabled(LD_FLAGS.BOTS_EXTERNAL_ENABLED)) {
      return HelperService.isAuthorized(ROLES_BOTS);
    }
    return HelperService.isAuthorized(ROLES_BOTS_INTERNAL);
  },
  [NAV_TABS.KNOWLEDGEBASES]: () => {
    if (!HelperService.isEnabled(LD_FLAGS.KNOWLEDGEBASES_ENABLED)) {
      return false;
    }
    if (HelperService.isEnabled(LD_FLAGS.KNOWLEDGEBASES_EXTERNAL_ENABLED)) {
      return HelperService.isAuthorized(ROLES_KNOWLEDGE);
    }
    return HelperService.isAuthorized(ROLES_KNOWLEDGE_INTERNAL);
  },
  [NAV_TABS.CONNECTORS]: () => {
    if (!HelperService.isEnabled(LD_FLAGS.CONNECTORS_ENABLED)) {
      return false;
    }
    if (HelperService.isEnabled(LD_FLAGS.CONNECTORS_EXTERNAL_ENABLED)) {
      return HelperService.isAuthorized(ROLES_CONNECTORS);
    }
    return HelperService.isAuthorized(ROLES_CONNECTORS_INTERNAL);
  },
  [NAV_TABS.CATEGORIES]: () => {
    return HelperService.isAuthorized(ROLES_CATEGORIES);
  },
  [NAV_TABS.SUMMARIES]: () => {
    if (!HelperService.isEnabled(LD_FLAGS.SUMMARIES_ENABLED)) {
      return false;
    }
    if (HelperService.isEnabled(LD_FLAGS.SUMMARIES_EXTERNAL_ENABLED)) {
      return HelperService.isAuthorized(ROLES_SUMMARIES);
    }
    return HelperService.isAuthorized(ROLES_SUMMARIES_INTERNAL);
  },
  [NAV_TABS.ADMIN]: () => {
    return HelperService.isAuthorized([ROLES.ADMIN]);
  },
  [NAV_TABS.USERS]: () => {
    return HelperService.isAuthorized(ROLES_USERS);
  },
  [NAV_TABS.SANDBOX]: () => {
    return (
      HelperService.isAuthorized(ROLES_PRIVILEGED) &&
      HelperService.isEnabled(LD_FLAGS.SANDBOX_ENABLED)
    );
  },
  [NAV_TABS.AGENT_WIDGET]: () => {
    return (
      HelperService.isAuthorized(ROLES_AGENT_WIDGET) &&
      HelperService.isEnabled(LD_FLAGS.AGENT_WIDGET_ENABLED)
    );
  },
};

export const TABS: NavTabMap<INavTab> = {
  [NAV_TABS.FLOWS]: {
    testid: TEST_IDS.nav.flows,
    icon: mdiGraphOutline,
    text: 'Flows',
    routeName: ROUTE_NAMES.FLOWS,
  },
  [NAV_TABS.CONVERSATION_HISTORY]: {
    testid: TEST_IDS.nav.conversationHistory,
    icon: mdiBookOpenOutline,
    text: 'Conversation History',
    routeName: ROUTE_NAMES.CONVERSATION_HISTORY,
  },
  [NAV_TABS.CONVERSATION_PLAYGROUND]: {
    testid: TEST_IDS.nav.conversationPlayground,
    icon: mdiSeesaw,
    text: 'Conversation Playground',
    routeName: ROUTE_NAMES.CONVERSATION_PLAYGROUND,
  },
  [NAV_TABS.SIMULATIONS]: {
    testid: TEST_IDS.nav.simulations,
    icon: mdiSitemapOutline,
    text: 'Simulations',
    routeName: ROUTE_NAMES.SIMULATIONS,
  },
  [NAV_TABS.TRANSCRIPT_ANALYSIS]: {
    testid: TEST_IDS.nav.transcriptAnalyzer,
    icon: mdiTextBoxSearchOutline,
    text: 'Transcript Analyzer',
    routeName: ROUTE_NAMES.TRANSCRIPT_ANALYSIS,
  },
  [NAV_TABS.EXTRAS]: {
    testid: TEST_IDS.nav.extras,
    icon: mdiSitemapOutline,
    text: 'Extras',
  },
  [NAV_TABS.BOTS]: {
    parentId: NAV_TABS.EXTRAS,
    testid: TEST_IDS.nav.bots,
    icon: mdiRobotOutline,
    text: 'Bots',
    routeName: ROUTE_NAMES.BOTS,
  },
  [NAV_TABS.KNOWLEDGEBASES]: {
    parentId: NAV_TABS.EXTRAS,
    testid: TEST_IDS.nav.knowledge,
    icon: mdiBookOutline,
    text: 'Knowledge',
    routeName: ROUTE_NAMES.KNOWLEDGEBASES,
  },
  [NAV_TABS.CONNECTORS]: {
    parentId: NAV_TABS.EXTRAS,
    testid: TEST_IDS.nav.connectors,
    icon: mdiPowerPlugOutline,
    text: 'Connectors',
    routeName: ROUTE_NAMES.CONNECTORS,
  },
  [NAV_TABS.SUMMARIES]: {
    parentId: NAV_TABS.EXTRAS,
    testid: TEST_IDS.nav.summaries,
    icon: mdiBookSearchOutline,
    text: 'Summaries',
    routeName: ROUTE_NAMES.SUMMARIES,
  },
  [NAV_TABS.ADMIN]: {
    testid: TEST_IDS.nav.admin,
    icon: mdiCogOutline,
    text: 'Admin',
  },
  [NAV_TABS.USERS]: {
    parentId: NAV_TABS.ADMIN,
    testid: TEST_IDS.nav.users,
    icon: mdiAccountMultipleOutline,
    text: 'Users',
    routeName: ROUTE_NAMES.USERS,
  },
  [NAV_TABS.CATEGORIES]: {
    parentId: NAV_TABS.ADMIN,
    testid: TEST_IDS.nav.categories,
    icon: mdiShapeOutline,
    text: 'Categories',
    routeName: ROUTE_NAMES.CATEGORIES,
  },
  [NAV_TABS.SANDBOX]: {
    parentId: NAV_TABS.ADMIN,
    testid: TEST_IDS.nav.sandbox,
    icon: mdiSeesaw,
    text: 'Sandbox',
    routeName: ROUTE_NAMES.SANDBOX,
  },
  [NAV_TABS.AGENT_WIDGET]: {
    testid: TEST_IDS.nav.agentWidget,
    icon: mdiFaceAgent,
    text: 'Agent Widget',
    routeName: ROUTE_NAMES.AGENT_WIDGET,
    hidden: true,
  },
};

export const TAB_IDS = Object.keys(TABS) as NAV_TABS[];

export const findFirstAvailableTabId = (ids: NAV_TABS[]) => {
  return ids.find((id) => CAN_ACCESS_TAB[id]());
};
