import {
  ACTION_KEYS,
  AGENT_WIDGET_DATA_PATHS,
  AGENT_WIDGET_SOURCE,
  API_ROUTES,
  CONVERSATION_SOURCE,
  MODEL_SUGGESTION_STATUS,
  MODEL_TYPE,
  SPEAKER,
  STORAGE_KEYS,
  V1,
  V2,
} from '@/constants/constants';
import {
  IAgentWidgetChatInfo,
  IAgentWidgetChatTranscript,
  IAgentWidgetModelSuggestions,
  IAgentWidgetSuggestion,
  IAgentWidgetUpdate,
  IMessage,
  IModel,
} from '@/interfaces/interfaces';
import { defineStore } from 'pinia';
import { IAgentWidgetChatTranscriptLine } from '@/interfaces/interfaces';
import ErrorService from '@/services/ErrorService';
import { useModelsStore } from './models';
import { useUserStore } from './user';
import ApiService from '@/services/ApiService';
import { PutSuggestionDTO, GetModelResponseDTO } from '@/interfaces/dtos';
import HelperService from '@/services/HelperService';
import {
  from,
  mergeMap,
  catchError,
  firstValueFrom,
  of,
  toArray,
  map,
} from 'rxjs';
import {
  AgentWidgetChatInfo,
  AgentWidgetChatLine,
} from '@/constants/agent-widget.constants';
import { useFlowsStore } from './flows';
import { IFlow } from '@/interfaces/flows.interfaces';

export interface IAgentWidgetConfig {
  randomizeModelOrder: boolean;
  anonymizeModelNames: boolean;
}

export interface IAgentWidgetState {
  initialized: boolean;
  loading: boolean;
  loaded: boolean;
  chatInfo: IAgentWidgetChatInfo | null;
  chatTranscriptLineEntities: Record<string, IAgentWidgetChatTranscriptLine>;
  chatTranscriptLineIds: string[];
  suggestionEntities: Record<string, IAgentWidgetSuggestion>;
  suggestionsByModel: Record<string, IAgentWidgetModelSuggestions>;
  debugMode: boolean;
  config: IAgentWidgetConfig;
}

const getSDK = () => {
  return (window as any).lpTag.agentSDK;
};

const toggleBindSDK = (
  bind: boolean,
  path: string,
  onUpdate: Function,
  onNotify: Function,
) => {
  if (bind) {
    getSDK().bind(path, onUpdate, onNotify);
  } else {
    getSDK().unbind(path, onUpdate, onNotify);
  }
};

export const useAgentWidgetStore = defineStore('agentWidget', {
  state: (): IAgentWidgetState => ({
    initialized: false,
    loading: false,
    loaded: false,
    chatInfo: null,
    chatTranscriptLineEntities: {},
    chatTranscriptLineIds: [],
    suggestionEntities: {},
    suggestionsByModel: {},
    debugMode: localStorage.getItem(STORAGE_KEYS.AGENT_WIDGET_DEBUG) === 'true',
    config: {
      randomizeModelOrder: false,
      anonymizeModelNames: false,
    },
  }),
  getters: {
    conversationId(state): string {
      return state.chatInfo?.isMessaging
        ? state.chatInfo.rtSessionId
        : `${state.chatInfo?.accountId}${state.chatInfo?.rtSessionId}`;
    },
    messagesFromTranscriptLine(state): IMessage[] {
      // take all lines up until the last agent line (before the last consumer)
      const ids = state.chatTranscriptLineIds.slice(0, this.lastAgentIndex + 1);
      return ids
        .map((id) => {
          const entity = state.chatTranscriptLineEntities[id];
          return {
            id,
            text: entity.text,
            speaker:
              entity.source !== AGENT_WIDGET_SOURCE.VISITOR
                ? SPEAKER.agent
                : SPEAKER.consumer,
            time: new Date(entity.time).getTime(),
          };
        })
        .filter((item) => !!item.text && typeof item.text === 'string');
    },
    suggestionsCount(state) {
      return Object.values(state.suggestionEntities).length;
    },
    conversationState(state) {
      return state.chatInfo?.dialogs[state.chatInfo?.rtSessionId]?.state;
    },
    isLastLineConsumer(state): boolean {
      return (
        !!state.chatTranscriptLineIds.length &&
        this.lastConsumerIndex === state.chatTranscriptLineIds.length - 1
      );
    },
    isLastLineConsumerAndNotEmpty(): boolean {
      return this.isLastLineConsumer && !!this.lastConsumerText;
    },
    lastAgentIndex(state) {
      return state.chatTranscriptLineIds.findLastIndex((id) => {
        const entity = state.chatTranscriptLineEntities[id];
        return entity.source !== AGENT_WIDGET_SOURCE.VISITOR;
      });
    },
    lastConsumerIndex(state): number {
      return state.chatTranscriptLineIds.findLastIndex((id) => {
        const entity = state.chatTranscriptLineEntities[id];
        return entity.source === AGENT_WIDGET_SOURCE.VISITOR;
      });
    },
    lastConsumerId(state): string {
      return state.chatTranscriptLineIds[this.lastConsumerIndex];
    },
    lastConsumerLine(state): IAgentWidgetChatTranscriptLine {
      return state.chatTranscriptLineEntities[this.lastConsumerId];
    },
    lastConsumerText(state): string {
      // from the last consumer index, find the next of a non-consumer
      const takeUntilIdx = this.lastConsumerIndex + 1;
      const remainingIds = state.chatTranscriptLineIds.slice(0, takeUntilIdx);
      const firstIdx = remainingIds.findLastIndex((id) => {
        const entity = state.chatTranscriptLineEntities[id];
        return entity.source !== AGENT_WIDGET_SOURCE.VISITOR;
      });

      // take the slice of last concurrent consumer messages
      const lastConsumerIds =
        firstIdx === -1 ? remainingIds : remainingIds.slice(firstIdx + 1);

      // concatenate the text with a linebreak
      return (
        lastConsumerIds
          .reduce((prev, id) => {
            const entity = state.chatTranscriptLineEntities[id];
            return prev ? `${prev}\n${entity.text}` : entity.text;
          }, '')
          // take the last 500 characters
          .slice(-500)
      );
    },
    chatTranscriptLines(state) {
      return state.chatTranscriptLineIds.map(
        (id) => state.chatTranscriptLineEntities[id],
      );
    },
    modelsBySkill(state) {
      const { models } = useModelsStore();
      const skill = `${state.chatInfo?.chatSkill}`;
      const account = state.chatInfo?.accountId;
      const items = models.filter((model) => {
        if (!account || account !== model.account_id) {
          return false;
        }
        if (!model.agent_widget_enabled) {
          return false;
        }
        if (model.assigned_skills?.length) {
          return model.assigned_skills.includes(skill);
        }
        return true;
      });
      return state.config.randomizeModelOrder
        ? items.sort(() => 0.5 - Math.random())
        : items;
    },
    flowsBySkill(state): IFlow[] {
      const { flows } = useFlowsStore();
      const skill = `${state.chatInfo?.chatSkill}`;
      const account = state.chatInfo?.accountId;
      const items = flows.filter((flow) => {
        if (!account || account !== flow.account_id) {
          return false;
        }
        if (!flow.agent_widget_enabled) {
          return false;
        }
        if (flow.agent_widget_skills?.length) {
          return flow.agent_widget_skills.includes(skill);
        }
        return true;
      });
      return state.config.randomizeModelOrder
        ? items.sort(() => 0.5 - Math.random())
        : items;
    },
  },
  actions: {
    initAgentWidgetConfig(config: Partial<IAgentWidgetConfig>) {
      this.$patch({
        config: {
          anonymizeModelNames: config.anonymizeModelNames ?? false,
          randomizeModelOrder: config.randomizeModelOrder ?? false,
        },
      });
    },
    generateChatInfo(skill?: string) {
      const { userData } = useUserStore();
      if (!userData || !userData.account_id) {
        throw Error('Missing user data or account ID');
      }
      const info = new AgentWidgetChatInfo({
        rtSessionId: userData.uid,
        accountId: userData.account_id,
        chatSkill: skill,
      });
      const update: IAgentWidgetUpdate<IAgentWidgetChatInfo> = {
        key: 'chatInfo',
        newValue: info,
      };
      this.updateChatInfo(update);
    },
    generateChatLine(text: string, source: AGENT_WIDGET_SOURCE) {
      const line = new AgentWidgetChatLine({
        text,
        source,
      });
      const update: IAgentWidgetUpdate<IAgentWidgetChatTranscript> = {
        key: 'lines',
        newValue: {
          lines: [line],
        },
      };
      this.updateChatTranscript(update);
    },
    resetAgentWidget() {
      this.$reset();
    },
    initAgentWidgetSdk() {
      if (!this.initialized) {
        getSDK().init();
        this.initialized = true;
      }
    },
    notifyWhenDone(err: any) {
      if (err) {
        console.error('[WidgetSDK] notifyWhenDone', err);
        ErrorService.handleRequestError(err);
        this.loading = false;
        return;
      }
      console.debug('[WidgetSDK] notifyWhenDone');
      this.loading = false;
    },
    updateChatTranscript(
      update: IAgentWidgetUpdate<IAgentWidgetChatTranscript>,
    ) {
      console.debug('[WidgetSDK] updateChatTranscript', { update });
      // using set to remove dupes which may occur on revist previous open conversation
      const ids = new Set(this.chatTranscriptLineIds);
      (update.newValue?.lines ?? []).forEach((line) => {
        // for some reason, the SDK returns new messages with id containing "::"
        // but when the same messages are returned on refresh or revisit, the ids contain ":"
        // which cause dupes to be rendered so we replace "::" with ":" to make sure the IDs are unique
        const id = line.id.replace('::', ':');
        // removes the opening/closing div tags that are being sent by the SDK
        line.text = line.text
          .replace(/<div>/i, '')
          .replace(/<\/div>/i, '')
          .trim();
        this.chatTranscriptLineEntities[id] = line;
        ids.add(id);
      });

      this.$patch({
        chatTranscriptLineIds: Array.from(ids),
        loading: false,
        loaded: true,
      });

      this.generateNewSuggestions();
    },
    updateChatInfo(update: IAgentWidgetUpdate<IAgentWidgetChatInfo>) {
      console.debug('[WidgetSDK] updateChatInfo', { update });
      this.$patch({
        chatInfo: {
          ...this.chatInfo,
          ...update.newValue,
        },
        loading: false,
        loaded: true,
      });

      this.generateNewSuggestions();
    },
    toggleBindChatTranscript(bind: boolean) {
      if (this.debugMode) {
        return;
      }
      this.loading = true;
      toggleBindSDK(
        bind,
        AGENT_WIDGET_DATA_PATHS.CHAT_TRANSCRIPT,
        this.updateChatTranscript,
        this.notifyWhenDone,
      );
    },
    toggleBindChatInfo(bind: boolean) {
      if (this.debugMode) {
        return;
      }
      this.loading = true;
      toggleBindSDK(
        bind,
        AGENT_WIDGET_DATA_PATHS.CHAT_INFO,
        this.updateChatInfo,
        this.notifyWhenDone,
      );
    },
    sendSuggestion(suggestionId: string) {
      const commandName = getSDK().cmdNames.write;
      const suggestion = this.suggestionEntities[suggestionId];
      suggestion.is_negative = false;
      suggestion.is_sent = true;
      const data = { text: suggestion.suggested_text };
      getSDK().command(commandName, data, this.notifyWhenDone);

      const body: PutSuggestionDTO = {
        message_id: suggestion.message_id,
        suggested_text: suggestion.suggested_text,
        is_negative: suggestion.is_negative,
        is_sent: suggestion.is_sent,
        model_id: suggestion.model_id,
        flow_id: suggestion.flow_id,
        response_id: suggestion.response_id,
      };
      this.putSuggestion(suggestionId, body);
    },
    toggleSuggestionAsNegative(suggestionId: string, isNegative: boolean) {
      const suggestion = this.suggestionEntities[suggestionId];
      suggestion.is_negative = isNegative;

      const body: PutSuggestionDTO = {
        model_id: suggestion.model_id,
        flow_id: suggestion.flow_id,
        message_id: suggestion.message_id,
        suggested_text: suggestion.suggested_text,
        is_negative: suggestion.is_negative,
        is_sent: suggestion.is_sent,
        response_id: suggestion.response_id,
      };
      this.putSuggestion(suggestionId, body);
    },
    async generateNewSuggestions() {
      if (!this.isLastLineConsumerAndNotEmpty) {
        return;
      }
      const modelsAndFlows = [...this.modelsBySkill, ...this.flowsBySkill];
      this.suggestionEntities = {};
      this.suggestionsByModel = modelsAndFlows.reduce((prev, item) => {
        prev[item.id] = {
          status: MODEL_SUGGESTION_STATUS.LOADING,
          suggestionIds: [],
        };
        return prev;
      }, {} as Record<string, IAgentWidgetModelSuggestions>);

      const obs = from(modelsAndFlows).pipe(
        mergeMap((item) => {
          return from(this.getSuggestionFromModelOrFlow(item)).pipe(
            map((result) => {
              if (result) {
                const isModel = HelperService.isModel(item);
                const dto: PutSuggestionDTO = {
                  suggested_text: result.message.text,
                  message_id: this.lastConsumerId,
                  model_id: isModel ? item.id : undefined,
                  flow_id: isModel ? undefined : item.id,
                  is_negative: false,
                  is_sent: false,
                  response_id: result.message.id!,
                };
                const suggestion: IAgentWidgetSuggestion = {
                  ...dto,
                  id: HelperService.uuid(),
                };
                this.suggestionEntities[suggestion.id] = suggestion;
                this.suggestionsByModel[result?.id] = {
                  status: MODEL_SUGGESTION_STATUS.COMPLETE,
                  suggestionIds: [suggestion.id],
                };
                this.putSuggestion(suggestion.id, dto);
              }
            }),
            catchError((err) => {
              this.suggestionsByModel[item.id] = {
                status: MODEL_SUGGESTION_STATUS.ERROR,
                suggestionIds: [],
              };
              console.error('[WidgetSDK] getSuggestionFromModel', err);
              return of(null);
            }),
          );
        }),
        catchError((err) => {
          console.error('[WidgetSDK] generateNewSuggestions', err);
          return of(null);
        }),
        toArray(),
      );
      await firstValueFrom(obs);
    },
    async getSuggestionFromModelOrFlow(item: IModel | IFlow) {
      const actionKey = `${ACTION_KEYS.GET_SUGGESTION}${item.id}`;

      try {
        if (!item.account_id) {
          throw Error('Model is missing account ID');
        }

        if (!this.chatInfo?.rtSessionId) {
          throw Error('Missing conversation ID');
        }

        this.loading = true;

        const isModel = HelperService.isModel(item);

        const url = isModel
          ? API_ROUTES.MODELS_BY_TYPE_ID(
              item.model_type,
              item.id,
              item.model_type === MODEL_TYPE.GENERIC_SIMPLE ? V2 : V1,
            )
          : API_ROUTES.FLOWS_BY_ID(item.id, V2);

        const body: GetModelResponseDTO = {
          text: this.lastConsumerText,
          text_id: this.lastConsumerId,
          conv_id: this.conversationId,
          messages: this.messagesFromTranscriptLine,
          source: CONVERSATION_SOURCE.CONVERSATIONAL_CLOUD,
          save_answer: false,
          flow_id: isModel ? undefined : item.id,
        };

        const { data } = await ApiService.post<IMessage | IMessage[]>(
          url,
          body,
          actionKey,
        );

        const message = Array.isArray(data) ? data[0] : data;

        return {
          message: message,
          id: item.id,
        };
      } catch (error) {
        const snackbar = ErrorService.handleRequestError(error);
        if (snackbar) throw error;
      } finally {
        this.loading = ApiService.hasInflightRequest(actionKey);
      }
    },
    async putSuggestion(id: string, dto: PutSuggestionDTO) {
      const actionKey = `${ACTION_KEYS.PUT_SUGGESTION}`;

      try {
        if (!this.chatInfo?.rtSessionId) {
          throw Error('Missing conversation ID');
        }

        this.loading = true;

        const url = API_ROUTES.SUGGESTIONS_BY_ID(this.conversationId, id);

        const { data } = await ApiService.put<IAgentWidgetSuggestion>(
          url,
          dto,
          actionKey,
        );
        return data;
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = ApiService.hasInflightRequest(actionKey);
      }
    },
  },
});
