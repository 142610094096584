import {
  ACTION_KEYS,
  EXPORT_TYPE,
  SPEAKER,
  FILTER_QUERY_TYPE,
  FILTER_DIRECTION,
  UNKNOWN_SOURCE,
  API_ROUTES,
  MODEL_TYPE,
  CONVERSATION_SOURCE,
  V2,
  V1,
} from '@/constants/constants';
import {
  IConversation,
  IConversationExport,
  IConversationFeedback,
  IFilters,
  IMessage,
  IRating,
} from '@/interfaces/interfaces';
import ApiService from '@/services/ApiService';
import { defineStore } from 'pinia';
import { useModelsStore } from '@/store/models';
import FileService from '@/services/FileService';
import ErrorService from '@/services/ErrorService';
import { useAppStore } from './app';
import { useSummaryStore } from './summary';
import { useQueryStore } from './query';
import { useNotifyStore } from './notify';
import HelperService from '@/services/HelperService';
import {
  CreateConversationDTO,
  GetModelResponseDTO,
  UpdateConversationDTO,
  UploadConversationDTO,
} from '@/interfaces/dtos';
import { useEvaluationStore } from './evaluation';
import { useFlowsStore } from './flows';
import { ROUTE_NAMES } from '@/constants/routes';

export interface IConversationState {
  loading: boolean;
  creating: boolean;
  isTypingMessage: boolean;
  conversation: IConversation | null;
  conversations: IConversation[];
  filters: IFilters;
  includeUntil: number | null;
  conversationEntities: Record<string, IConversation>;
}

export const useConversationStore = defineStore('conversation', {
  state: (): IConversationState => ({
    loading: false,
    creating: false,
    isTypingMessage: false,
    conversation: null,
    conversations: [],
    filters: {
      query_type: FILTER_QUERY_TYPE.PRIVATE,
      page: 0,
      limit: 10,
      direction: FILTER_DIRECTION.DESCENDING,
      model_id: undefined,
      account_id: undefined,
      source: undefined,
    },
    includeUntil: null,
    conversationEntities: {},
  }),
  getters: {
    messagesToInclude(state): IMessage[] {
      return (
        (state.includeUntil
          ? state.conversation?.messages.slice(0, state.includeUntil)
          : state.conversation?.messages) ?? []
      );
    },
    lastConsumerIdx(): number {
      return (
        this.messagesToInclude.findLastIndex(
          (message) => message.speaker === SPEAKER.consumer,
        ) ?? -1
      );
    },
    lastAgentIdx(): number {
      if (this.includeUntil) {
        return this.includeUntil;
      }
      return (
        this.conversation?.messages.findLastIndex(
          (message) => message.speaker !== SPEAKER.consumer,
        ) ?? -1
      );
    },
    hasLastConsumerMessage(): boolean {
      return this.lastConsumerIdx !== -1;
    },
    hasLastAgentMessage(): boolean {
      return this.lastAgentIdx !== -1;
    },
    isLastMessageAgent(state): boolean {
      if (!state.conversation?.messages.length) return false;
      return (
        state.conversation?.messages[state.conversation.messages.length - 1]
          .speaker === SPEAKER.agent
      );
    },
    lastAgentIdxBeforeLastConsumerIdx(): number {
      const messagesUpToLastConsumer =
        this.messagesToInclude.slice(0, this.lastConsumerIdx + 1) ?? [];
      return (
        messagesUpToLastConsumer.findLastIndex(
          (message) => message.speaker === SPEAKER.agent,
        ) ?? -1
      );
    },
    hasMessagesUntilLastAgent(): boolean {
      return this.lastAgentIdxBeforeLastConsumerIdx !== -1;
    },
    messagesUntilLastAgent(): IMessage[] {
      if (!this.hasMessagesUntilLastAgent) {
        return [];
      }
      return (
        this.messagesToInclude.slice(
          0,
          this.lastAgentIdxBeforeLastConsumerIdx + 1,
        ) ?? []
      );
    },
    messagesBeforeLastConsumer(): IMessage[] {
      return this.messagesToInclude.slice(0, this.lastConsumerIdx) ?? [];
    },
    lastConsumerMessage(): IMessage | undefined {
      if (!this.hasLastConsumerMessage) return undefined;
      return this.messagesToInclude[this.lastConsumerIdx];
    },
    lastAgentMessage(): IMessage | undefined {
      if (!this.hasLastAgentMessage) return undefined;
      return this.conversation?.messages[this.lastAgentIdx];
    },
    averageFeedback(state) {
      return state.conversations.reduce((prev, conv) => {
        if (
          !conv.feedback ||
          !(conv.feedback.behavior || conv.feedback.comfort)
        ) {
          return prev;
        }
        const avg = HelperService.aggregateEntriesValues({
          comfort: conv.feedback.comfort,
          behavior: conv.feedback.behavior,
        });
        prev[conv.id] = Math.round(avg * 100) / 100;
        return prev;
      }, {} as Record<string, number>);
    },
    averageMessageRating(state) {
      return state.conversations.reduce((prev, conv) => {
        if (!conv.feedback?.ratings) {
          return prev;
        }
        const avg = HelperService.aggregateEntriesValues(
          conv.feedback.ratings,
          100,
        );
        prev[conv.id] = Math.round(avg);
        return prev;
      }, {} as Record<string, number>);
    },
    canEditConversation(state) {
      return HelperService.canEditConversation(state.conversation);
    },
    feedback(state) {
      return {
        behavior: state.conversation?.feedback?.behavior,
        comfort: state.conversation?.feedback?.comfort,
        verbatim: state.conversation?.feedback?.verbatim,
      };
    },
    hasFeedback(state) {
      return Object.entries(state.conversation?.feedback ?? {}).length > 0;
    },
    wordCountExceeded(): boolean {
      const { maxWordLimit } = useAppStore();
      return this.wordCount >= maxWordLimit;
    },
    wordCount: (state) =>
      state.conversation?.messages.reduce((prev, msg) => {
        return prev + msg.text.split(' ').length;
      }, 0) ?? 0,
    isDebuggingMessage(): boolean {
      return !!(this.includeUntil && this.includeUntil > 0);
    },
  },
  actions: {
    patchFeedback(feedback: Partial<IConversationFeedback>) {
      this.$patch({ conversation: { feedback } });
      return feedback;
    },
    async updateFeedback(
      conversationId: string,
      feedback: Partial<IConversationFeedback>,
      doRoute: Boolean,
    ) {
      if (!this.conversation) {
        return;
      }
      const actionKey = ACTION_KEYS.UPDATE_FEEDBACK;
      try {
        this.$patch({
          conversation: {
            feedback: { ...feedback },
          },
        });

        const url = API_ROUTES.FEEDBACK_BY_ID(conversationId);
        const { data } = await ApiService.put<IConversation>(
          url,
          feedback,
          actionKey,
        );

        if (conversationId !== data.id && doRoute) {
          this.$router.push({
            name: ROUTE_NAMES.CONVERSATION_PLAYGROUND_SELECT,
            params: { id: data.id },
          });
        }

        this.$patch({ conversation: data });
        return data;
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = ApiService.hasInflightRequest(actionKey);
      }
    },
    async exportConversation(type: EXPORT_TYPE) {
      switch (type) {
        case EXPORT_TYPE.CSV: {
          return FileService.downloadAsCsv(
            this.conversation?.messages ?? [],
            this.conversation?.model_id ??
              this.conversation?.flow_id ??
              UNKNOWN_SOURCE.MODEL_ID,
          );
        }
      }
    },
    async uploadConversation(dto: UploadConversationDTO) {
      const actionKey = ACTION_KEYS.UPLOAD_CONVERSATION;

      try {
        this.loading = true;

        const url = API_ROUTES.CONVERSATIONS_UPLOAD();

        const { data } = await ApiService.post<IConversation[]>(
          url,
          dto,
          actionKey,
        );
        this.conversation = data[0];
        this.conversations = [...data, ...this.conversations];
        return data;
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = ApiService.hasInflightRequest(actionKey);
      }
    },
    async sendMessageModel(modelId: string, message: IMessage) {
      const { maxWordLimit, debugEnabled } = useAppStore();
      const { modelUserInputs, modelEntities } = useModelsStore();
      const actionKey = ACTION_KEYS.SEND_MESSAGE;

      if (!modelEntities[modelId]) throw Error('Model is missing');

      if (this.wordCount >= maxWordLimit) {
        return;
      }

      try {
        if (!this.conversation) throw Error('Conversation is missing');

        // TODO: why does patch not trigger change detection?
        this.conversation = {
          ...this.conversation,
          messages: [...this.conversation.messages, message],
        };
        this.$patch({
          isTypingMessage: true,
          loading: true,
        });

        const body: GetModelResponseDTO = {
          text: message.text,
          conv_id: this.conversation.id,
          source: CONVERSATION_SOURCE.AI_DOJO,
          debug: debugEnabled,
        };
        if (modelUserInputs.pre_prompt) {
          body.pre_prompt = modelUserInputs.pre_prompt;
        }
        if (modelUserInputs.user_profile) {
          body.user_profile = modelUserInputs.user_profile;
        }
        if (modelUserInputs.prompt_messages_count) {
          body.prompt_messages_count = modelUserInputs.prompt_messages_count;
        }
        if (modelUserInputs.prompt_header_override) {
          body.prompt_header_override = modelUserInputs.prompt_header_override;
        }

        const modelType = modelEntities[modelId].model_type;
        const apiVersion = modelType === MODEL_TYPE.GENERIC_SIMPLE ? V2 : V1;
        const url = API_ROUTES.MODELS_BY_TYPE_ID(
          modelType,
          modelId,
          apiVersion,
        );

        const { data } = await ApiService.post<IMessage | IMessage[]>(
          url,
          body,
          actionKey,
        );

        const messages = Array.isArray(data) ? data : [data];

        // TODO: why does patch not trigger change detection?
        this.conversation = {
          ...this.conversation,
          messages: [...this.conversation.messages, ...messages],
        };
        return this.conversation;
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        const hasInFlight = ApiService.hasInflightRequest(actionKey);
        this.$patch({
          loading: hasInFlight,
          isTypingMessage: hasInFlight,
        });
      }
    },
    async sendMessageFlow(flowId: string, message: IMessage) {
      const { maxWordLimit, debugEnabled } = useAppStore();
      const actionKey = ACTION_KEYS.SEND_MESSAGE;

      if (this.wordCount >= maxWordLimit) {
        return;
      }

      try {
        if (!this.conversation) throw Error('Conversation is missing');

        this.conversation = {
          ...this.conversation,
          messages: [...this.conversation.messages, message],
        };
        this.$patch({
          isTypingMessage: true,
          loading: true,
        });

        const body: GetModelResponseDTO = {
          text: message.text,
          conv_id: this.conversation.id,
          source: CONVERSATION_SOURCE.AI_DOJO,
          debug: debugEnabled,
        };

        const url = API_ROUTES.FLOWS_BY_ID(flowId);

        const { data } = await ApiService.post<IMessage | IMessage[]>(
          url,
          body,
          actionKey,
        );

        const messages = Array.isArray(data) ? data : [data];

        this.conversation = {
          ...this.conversation,
          messages: [...this.conversation.messages, ...messages],
        };
        return this.conversation;
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        const hasInFlight = ApiService.hasInflightRequest(actionKey);
        this.$patch({
          loading: hasInFlight,
          isTypingMessage: hasInFlight,
        });
      }
    },
    async createConversation(body: CreateConversationDTO, doRoute: boolean) {
      const { resetModelUserInputs } = useModelsStore();
      const { resetSummary } = useSummaryStore();
      const { resetResolution } = useEvaluationStore();
      const { resetQuery } = useQueryStore();
      const actionKey = ACTION_KEYS.CREATE_CONVERSATION;

      try {
        resetModelUserInputs();
        resetSummary();
        resetResolution();
        resetQuery();

        this.$patch({
          conversation: null,
          isTypingMessage: false,
          loading: true,
          creating: true,
          includeUntil: null,
        });

        const url = API_ROUTES.CONVERSATIONS();
        const { data } = await ApiService.post<IConversation>(
          url,
          body,
          actionKey,
        );
        this.conversation = data;
        if (doRoute) {
          this.$router.push({
            name: ROUTE_NAMES.CONVERSATION_PLAYGROUND_SELECT,
            params: { id: data.id },
          });
        }
        return data;
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        const hasInFlight = ApiService.hasInflightRequest(actionKey);
        this.$patch({
          loading: hasInFlight,
          isTypingMessage: hasInFlight,
          creating: false,
        });
      }
    },
    async getAndOpenOneConversation(id: string) {
      const { resetModelUserInputs, selectModel } = useModelsStore();
      const { resetSummary } = useSummaryStore();
      const { resetQuery } = useQueryStore();
      const { resetResolution } = useEvaluationStore();
      const { selectFlow, getFlowById } = useFlowsStore();
      const actionKey = ACTION_KEYS.GET_CONVERSATION;

      try {
        resetModelUserInputs();
        resetSummary();
        resetResolution();
        resetQuery();

        this.$patch({
          conversation: null,
          loading: true,
          includeUntil: null,
        });
        const url = API_ROUTES.CONVERSATIONS_BY_ID(id);
        const { data } = await ApiService.get<IConversation>(url, actionKey);
        this.conversation = data;
        if (data.model_id) selectModel(data.model_id);
        if (data.flow_id) {
          await getFlowById(data.flow_id);
          selectFlow(data.flow_id);
        }
        this.$router.push({
          name: ROUTE_NAMES.CONVERSATION_PLAYGROUND_SELECT,
          params: { id: data.id },
        });
        return data;
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        const hasInFlight = ApiService.hasInflightRequest(actionKey);
        this.$patch({
          loading: hasInFlight,
          isTypingMessage: hasInFlight,
        });
      }
    },
    async updateConversation(conversation: IConversation, doRoute: boolean) {
      const actionKey = ACTION_KEYS.UPDATE_CONVERSATION;
      this.loading = true;
      try {
        const url = API_ROUTES.CONVERSATIONS_BY_ID(conversation.id);
        const body: UpdateConversationDTO = {
          saved: true,
          public: conversation.public,
          custom_name: conversation.custom_name,
          custom_label: conversation.custom_label,
        };
        const { data } = await ApiService.put<IConversation>(
          url,
          body,
          actionKey,
        );
        if (conversation.id !== data.id && doRoute) {
          this.$router.push({
            name: ROUTE_NAMES.CONVERSATION_PLAYGROUND_SELECT,
            params: { id: data.id },
          });
        }
        const convIdx = this.conversations.findIndex(
          (conv) => conv.id === data.id,
        );
        if (convIdx !== -1) {
          this.conversations[convIdx] = data;
        }
        this.$patch({ conversation: data });
        return data;
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = ApiService.hasInflightRequest(actionKey);
      }
    },
    async deleteConversation(id: string) {
      const { showSnackbar } = useNotifyStore();
      const actionKey = ACTION_KEYS.DELETE_CONVERSATION;
      this.loading = true;
      try {
        const url = API_ROUTES.CONVERSATIONS_BY_ID(id);
        const { data } = await ApiService.delete<string>(url, actionKey);
        const index = this.conversations.findIndex((conv) => conv.id === data);

        this.$patch({
          conversation:
            this.conversation?.id === data ? null : this.conversation,
          conversations: [
            ...this.conversations.slice(0, index),
            ...this.conversations.slice(index + 1),
          ],
        });
        showSnackbar(`Conversation deleted: ${data}`);
        return data;
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = ApiService.hasInflightRequest(actionKey);
      }
    },
    async getOpenConvCloudConversations() {
      const actionKey = ACTION_KEYS.GET_CONV_CLOUD_OPEN_CONVERSATIONS;
      this.loading = true;
      try {
        const url = API_ROUTES.GET_CONV_CLOUD_OPEN_CONVERSATIONS();
        const { data } = await ApiService.get<IConversation[]>(url, actionKey);
        return data;
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        const hasInFlight = ApiService.hasInflightRequest(actionKey);
        this.$patch({ loading: hasInFlight });
      }
    },
    async closeConvCloudConversation(conversationId: string) {
      const actionKey = ACTION_KEYS.CLOSE_CONV_CLOUD_CONVERSATION;
      this.loading = true;
      try {
        const url = API_ROUTES.CLOSE_CONV_CLOUD_CONVERSATION(conversationId);
        const { data } = await ApiService.patch<string>(url, {}, actionKey);

        return data;
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = ApiService.hasInflightRequest(actionKey);
      }
    },
    async getConversationById(id: string) {
      const actionKey = `${ACTION_KEYS.GET_CONVERSATION}${id}`;

      try {
        this.$patch({ loading: true });

        const url = API_ROUTES.CONVERSATIONS_BY_ID(id);
        const { data } = await ApiService.get<IConversation>(url, actionKey);
        this.$patch({
          conversationEntities: { [id]: data },
          conversation: this.conversation?.id === id ? data : this.conversation,
        });

        return data;
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        const hasInFlight = ApiService.hasInflightRequest(actionKey);
        this.$patch({ loading: hasInFlight });
      }
    },
    async getManyConversations() {
      const actionKey = ACTION_KEYS.GET_CONVERSATION;

      try {
        this.$patch({ loading: true });
        const url = API_ROUTES.CONVERSATIONS();
        const { data } = await ApiService.get<IConversation[]>(
          url,
          actionKey,
          this.filters,
        );
        this.$patch({ conversations: data });
        return data;
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        const hasInFlight = ApiService.hasInflightRequest(actionKey);
        this.$patch({
          loading: hasInFlight,
          isTypingMessage: hasInFlight,
        });
      }
    },
    updateFilters(filters: Partial<IFilters>) {
      this.$patch({ filters });
    },
    async rateMessage(
      conversationId: string,
      index: number,
      rating: IRating,
      doRoute: boolean,
    ) {
      if (!this.conversation) {
        return;
      }
      const isValid =
        this.conversation.messages[index]?.speaker === SPEAKER.agent;
      if (!isValid) {
        return this.conversation.feedback?.ratings;
      }

      const ratings = { ...this.conversation.feedback?.ratings };
      ratings[index] = rating;
      const actionKey = ACTION_KEYS.UPDATE_FEEDBACK;

      try {
        this.$patch({
          conversation: {
            feedback: {
              ratings: { ...ratings },
            },
          },
        });

        const url = API_ROUTES.FEEDBACK_BY_ID(conversationId);
        const { data } = await ApiService.put<IConversation>(
          url,
          { ratings },
          actionKey,
        );

        if (conversationId !== data.id && doRoute) {
          this.$router.push({
            name: ROUTE_NAMES.CONVERSATION_PLAYGROUND_SELECT,
            params: { id: data.id },
          });
        }

        this.$patch({ conversation: data });
        return data;
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = ApiService.hasInflightRequest(actionKey);
      }
    },
    async exportConversations() {
      const { showSnackbar } = useNotifyStore();
      const actionKey = ACTION_KEYS.EXPORT_CONVERSATIONS;

      try {
        this.loading = true;

        showSnackbar(`Exporting conversation files, please wait`);

        const params: IFilters = {
          ...this.filters,
          page: 0,
          limit: 250,
        };

        let getRecords = true;
        const url = API_ROUTES.CONVERSATIONS_EXPORT();
        const timestamp = new Date().toISOString().substring(0, 19);
        let page = 1;

        while (getRecords) {
          const id = `${timestamp}-page[${page}]`;
          const { data } = await ApiService.get<IConversationExport>(
            url,
            actionKey,
            params,
          );
          FileService.downloadAsJson(data, id, 'conversations', false);
          getRecords = !!data.metadata?.href_next;
          if (data.metadata?.href_next) {
            const next = new URL(data.metadata.href_next);
            params.page_after_id =
              next.searchParams.get('page_after_id') ?? undefined;
            page++;
          }
        }
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = ApiService.hasInflightRequest(actionKey);
      }
    },
    resetConversations() {
      this.$reset();
    },
  },
});
