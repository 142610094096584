export enum ROLES {
  // basic user roles
  CONSUMER = 'CONSUMER', // LP ONLY: can use the conversation viewer and access the library
  EXTERNAL_CONSUMER = 'EXTERNAL_CONSUMER', // External to LP: can use the conversation viewer and access library and only see models it is assigned to
  EXTERNAL_MODEL_MANAGER = 'EXTERNAL_MODEL_MANAGER', // External to LP: can view and edit knowledgebase items assigned to their account
  AGENT = 'AGENT', // can use the agent widget and view only bots assigned to their skill

  // specialist management roles
  MODEL_MANAGER = 'MODEL_MANAGER', // LP ONLY: can create and manage their own bots and modify visibility

  // global management roles
  MANAGER = 'MANAGER', // LP ONLY: can manage all bots and view all conversations
  ADMIN = 'ADMIN', // LP ONLY: same as manager but can also manage other users
}

export const ROLES_CONSUMERS = [ROLES.CONSUMER, ROLES.EXTERNAL_CONSUMER];
export const ROLES_MODEL_MANAGERS = [
  ROLES.MODEL_MANAGER,
  ROLES.EXTERNAL_MODEL_MANAGER,
];
export const ROLES_PRIVILEGED = [ROLES.MANAGER, ROLES.ADMIN];

export const ROLES_INTERNAL = [
  ROLES.CONSUMER,
  ROLES.MODEL_MANAGER,
  ROLES.MANAGER,
  ROLES.ADMIN,
];
export const ROLES_EXTERNAL = [
  ROLES.EXTERNAL_CONSUMER,
  ROLES.EXTERNAL_MODEL_MANAGER,
];

export const ROLES_AGENT_WIDGET = ROLES_CONSUMERS.concat([ROLES.AGENT]);
export const ROLES_CONVERSATION_PLAYGROUND = [...ROLES_CONSUMERS];
export const ROLES_CONVERSATION_HISTORY = [...ROLES_CONSUMERS];
export const ROLES_SUMMARIES_INTERNAL = [...ROLES_INTERNAL];
export const ROLES_SUMMARIES = [...ROLES_EXTERNAL, ...ROLES_INTERNAL];
export const ROLES_SIMULATIONS = [...ROLES_CONSUMERS];
export const ROLES_TRANSCRIPT_ANALYSIS = [...ROLES_CONSUMERS];

export const ROLES_BOTS_INTERNAL = ROLES_PRIVILEGED.concat([
  ROLES.MODEL_MANAGER,
]);
export const ROLES_BOTS = ROLES_PRIVILEGED.concat(ROLES_MODEL_MANAGERS);
export const ROLES_KNOWLEDGE_INTERNAL = ROLES_PRIVILEGED.concat([
  ROLES.MODEL_MANAGER,
]);
export const ROLES_KNOWLEDGE = ROLES_PRIVILEGED.concat(ROLES_MODEL_MANAGERS);

export const ROLES_FLOWS = ROLES_PRIVILEGED.concat(ROLES_MODEL_MANAGERS);
export const ROLES_USERS = [ROLES.ADMIN];
export const ROLES_CATEGORIES = [...ROLES_PRIVILEGED];
export const ROLES_CONNECTORS_INTERNAL = ROLES_PRIVILEGED.concat([
  ROLES.MODEL_MANAGER,
]);
export const ROLES_CONNECTORS = ROLES_PRIVILEGED.concat(ROLES_MODEL_MANAGERS);
