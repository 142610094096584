import { useNotifyStore } from '@/store/notify';
import { AxiosError } from 'axios';
import { FirebaseError } from 'firebase/app';

export class ErrorService {
  handleRequestError(error: any) {
    const { showSnackbar } = useNotifyStore();
    if (error instanceof FirebaseError) {
      switch (error.code) {
        case 'auth/invalid-email':
          return showSnackbar('Please enter a valid email address');
        case 'auth/wrong-password':
        case 'auth/user-not-found':
          return showSnackbar('Email address or password is incorrect');
        default:
          console.error(error);
          return showSnackbar(
            'Sorry, something went wrong',
            error.message,
            5000,
          );
      }
    }

    if (error instanceof AxiosError) {
      if (error.code === AxiosError.ERR_CANCELED) {
        console.warn('Request cancelled');
        return;
      }

      const message =
        error.response?.data?.errorMessage ?? `${error.message} ${error.code}`;
      console.error(error);
      return showSnackbar('Sorry, something went wrong', message, 5000);
    }

    console.error(error);
    return showSnackbar('Sorry, something went wrong', error.message, 5000);
  }
}

export default new ErrorService();
