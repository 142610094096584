<template>
  <v-row class="fill-height" justify="center" align="center">
    <v-col cols="12" sm="5">
      <v-row>
        <v-col class="text-h4 font-weight-thin"> Sorry! This is a no go </v-col>
      </v-row>
      <v-row>
        <v-col class="text-body-1">
          Currently, only authorized users are allowed. For permission to access
          this page, contact
          <a href="mailto:aidojo-LP@liveperson.com" target="_blank">
            aidojo-LP@liveperson.com
          </a>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" sm="5">
      <v-img
        v-if="theme.current.value.dark"
        height="300"
        src="@/assets/no-access_dark.png"
      ></v-img>
      <v-img v-else height="300" src="@/assets/no-access_light.png"></v-img>
    </v-col>
  </v-row>
</template>

<script lang="ts" setup>
import { useTheme } from 'vuetify';

const theme = useTheme();
</script>
