import {
  ACTION_KEYS,
  API_ROUTES,
  DEFAULT_PROMPT_MESSAGES_COUNT,
  MODEL_FILTER_TYPE,
  MODEL_TYPE,
  MODEL_TYPE_LABELS,
} from '@/constants/constants';
import type {
  ICustomBotProps,
  IModel,
  IModelFilters,
  IModelUserInputs,
  IProcessorNames,
} from '@/interfaces/interfaces';
import ApiService from '@/services/ApiService';
import { defineStore } from 'pinia';
import ErrorService from '@/services/ErrorService';
import { CreateModelDTO, UpdateModelDTO } from '@/interfaces/dtos';
import { useNotifyStore } from './notify';
import { useUserStore } from './user';
import { useUsersStore } from './users';
import HelperService from '@/services/HelperService';

export interface IModelState {
  loading: boolean;
  loaded: boolean;
  selectedModelId: string | null;
  modelEntities: Record<string, IModel>;
  modelIds: string[];
  modelUserInputs: IModelUserInputs;
  filters: IModelFilters;
}

export const useModelsStore = defineStore('models', {
  state: (): IModelState => ({
    loading: false,
    loaded: false,
    selectedModelId: null,
    modelUserInputs: {
      pre_prompt: '',
      user_profile: '',
      prompt_messages_count: DEFAULT_PROMPT_MESSAGES_COUNT,
      prompt_header_override: '',
    },
    modelEntities: {},
    modelIds: [],
    filters: {
      keyword: null,
      type: MODEL_FILTER_TYPE.ALL_MODELS,
    },
  }),
  getters: {
    selectedModelHasDebugger(): boolean {
      return HelperService.modelHasDebugger(this.selectedModel);
    },
    selectedModelHasSettings(): boolean {
      return !!HelperService.modelHasSettings(this.selectedModel);
    },
    isLangchainFlow(): boolean {
      return this.selectedModel?.model_type === MODEL_TYPE.LANGCHAIN_FLOW;
    },
    models: (state) =>
      state.modelIds
        .map((id) => state.modelEntities[id])
        .sort((a, b) => (b.created_at ?? 0) - (a.created_at ?? 0)),
    modelsEnabled(): IModel[] {
      return this.models.filter((model) => model.enabled);
    },
    modelsEnabledPublic(): IModel[] {
      return this.models.filter((model) => model.enabled && !model.private);
    },
    modelsFilteredEnabled(): IModel[] {
      return this.modelsFiltered.filter((model) => model.enabled);
    },
    modelsLangchainFlow(): IModel[] {
      return this.models.filter(
        (model) => model.model_type === MODEL_TYPE.LANGCHAIN_FLOW,
      );
    },
    modelsFiltered(): IModel[] {
      const { userData } = useUserStore();
      const { userEntities } = useUsersStore();
      const isAllBots = this.filters.type === MODEL_FILTER_TYPE.ALL_MODELS;

      if (!this.filters.keyword && isAllBots) {
        return this.models;
      }

      return this.models.filter((model) => {
        let hasType = true;
        let hasKeyword = true;

        switch (this.filters.type) {
          case MODEL_FILTER_TYPE.MY_MODELS:
            hasType = model.created_by === userData?.uid;
            break;
          case MODEL_FILTER_TYPE.FAV_MODELS:
            hasType = !!userData?.favorite_models.includes(model.id);
            break;
        }

        if (this.filters.keyword) {
          const fields = [
            model.display_name,
            model.display_description,
            model.id,
            MODEL_TYPE_LABELS[model.model_type] ?? model.model_type,
          ];
          const user = userEntities[model.created_by];
          if (user) {
            fields.push(user.display_name, user.email);
          }
          if (model.account_id) {
            fields.push(model.account_id);
          }
          if (model.assigned_skills) {
            fields.push(model.assigned_skills.join(''));
          }
          if (model.knowledgebases_ids) {
            fields.push(model.knowledgebases_ids.join(''));
          }
          if (model.langchain_flow_id) {
            fields.push(model.langchain_flow_id);
          }
          const textToSearch = fields.join('').toLowerCase();
          const keyword = (this.filters.keyword ?? '').toLowerCase();
          hasKeyword = textToSearch.toLowerCase().includes(keyword);
        }

        return hasType && hasKeyword;
      });
    },
    selectedModel: (state) =>
      state.selectedModelId ? state.modelEntities[state.selectedModelId] : null,
    customBotProps(): ICustomBotProps {
      try {
        return JSON.parse(this.selectedModel?.custom_bot_props || '{}');
      } catch (error) {
        return {};
      }
    },
    processorNames(): IProcessorNames {
      return this.customBotProps.processors ?? {};
    },
  },
  actions: {
    async getModels(
      includeDetails: boolean = false,
      byAccountId: boolean = false,
    ) {
      this.loading = true;
      const actionKey = ACTION_KEYS.GET_MODELS;
      try {
        const url = includeDetails
          ? API_ROUTES.MODELS_DETAILS()
          : API_ROUTES.MODELS();
        const { data } = await ApiService.get<IModel[]>(url, actionKey);
        const ids: string[] = [];
        const entities: Record<string, IModel> = data.reduce((prev, model) => {
          ids.push(model.id);
          prev[model.id] = model;
          return prev;
        }, {} as Record<string, IModel>);
        this.$patch({
          modelIds: ids,
          modelEntities: entities,
          loaded: true,
        });
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = ApiService.hasInflightRequest(actionKey);
      }
    },
    selectModel(id?: string) {
      this.$patch({ selectedModelId: id });
      return id ? this.modelEntities[id] : null;
    },
    async createModel(body: CreateModelDTO) {
      const { showSnackbar } = useNotifyStore();
      const actionKey = ACTION_KEYS.CREATE_MODEL;
      this.loading = true;

      try {
        const url = API_ROUTES.MODELS();
        const { data } = await ApiService.post<IModel>(url, body, actionKey);
        this.$patch({
          modelIds: [...this.modelIds, data.id],
          modelEntities: {
            ...this.modelEntities,
            [data.id]: data,
          },
        });
        showSnackbar(`Model created: ${data.id}`);
        return data;
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = ApiService.hasInflightRequest(actionKey);
      }
    },
    async updateModel(body: UpdateModelDTO, id: string) {
      const { showSnackbar } = useNotifyStore();
      const actionKey = `${ACTION_KEYS.UPDATE_MODEL}${id}`;
      this.loading = true;

      try {
        const url = API_ROUTES.MODELS_BY_ID(id);
        const { data } = await ApiService.put<IModel>(url, body, actionKey);
        this.$patch({
          modelEntities: {
            ...this.modelEntities,
            [data.id]: data,
          },
        });
        showSnackbar(`Bot updated: ${id}`);
        return this.modelEntities[id];
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = ApiService.hasInflightRequest(actionKey);
      }
    },
    async deleteModel(id: string) {
      const { showSnackbar } = useNotifyStore();
      const actionKey = ACTION_KEYS.DELETE_MODEL;
      this.loading = true;

      try {
        const url = API_ROUTES.MODELS_BY_ID(id);
        const { data } = await ApiService.delete<string>(url, actionKey);
        const { [data]: toRemove, ...modelEntities } = this.modelEntities;
        const index = this.modelIds.findIndex((id) => id === toRemove.id);
        this.$patch({
          modelIds: [
            ...this.modelIds.slice(0, index),
            ...this.modelIds.slice(index + 1),
          ],
          modelEntities: {
            ...modelEntities,
          },
        });
        showSnackbar(`Bot deleted: ${id}`);
      } catch (error) {
        ErrorService.handleRequestError(error);
      } finally {
        this.loading = ApiService.hasInflightRequest(actionKey);
      }
    },
    resetModelUserInputs() {
      this.$patch({
        modelUserInputs: {
          pre_prompt: '',
          user_profile: '',
          prompt_messages_count: DEFAULT_PROMPT_MESSAGES_COUNT,
          prompt_header_override: '',
        },
      });
    },
    resetModels() {
      this.$reset();
    },
  },
});
